import { TipoMontadora } from "../enums/TipoMontadora";
import { Montadora } from "./Montadora";

export class Montadoras {
    static MONTADORAS = {
        APRESENTACAO: new Montadora(
            TipoMontadora.JAGUAR,
            "Jaguar",
            "/img/android/ic_launcher.png",
            "/img/android/ic_launcher.png",
            "J",
            "JAGUAR"
        ),
        BMW: new Montadora(TipoMontadora.BMW, "BMW", "/img/bmw/bmw-selecao.png", "/img/bmw/logo-bmw.png", "B", "BMW"),
        MINI: new Montadora(
            TipoMontadora.MINI,
            "MINI",
            "/img/bmw/mini-selecao.png",
            "/img/bmw/logo-mini.png",
            "M",
            "MINI"
        ),
        BMW_MOTORRAD: new Montadora(
            TipoMontadora.BMW_MOTORRAD,
            "BMW",
            "/img/bmw/bmw-motorrad-selecao.png",
            "/img/bmw/logo-bmw-motorrad.png",
            "R",
            "BMW_MOTORRAD"
        ),
        AUDI: new Montadora(
            TipoMontadora.AUDI,
            "AUDI",
            "/img/audi/splash-audi.png",
            "/img/audi/splash-audi.png",
            "A",
            "AUDI"
        ),
        JAGUAR: new Montadora(
            TipoMontadora.JAGUAR,
            "JAGUAR",
            "/img/marcas/jaguar-selecao.png",
            "/img/marcas/jaguar-logo.png",
            "J",
            "JAGUAR"
        ),
        LAND_ROVER: new Montadora(
            TipoMontadora.LAND_ROVER,
            "LAND ROVER",
            "/img/marcas/land-rover-selecao.png",
            "/img/marcas/land-rover-logo.png",
            "LR",
            "LAND_ROVER"
        ),
        HARLEY: new Montadora(
            TipoMontadora.HARLEY,
            "H.DAVIDSON",
            "/img/harley/splash-harley.png",
            "/img/harley/splash-harley.png",
            "HD",
            "HARLEY"
        ),
        TRIUMPH: new Montadora(
            TipoMontadora.TRIUMPH,
            "TRIUMPH",
            "/img/triumph/splash-triumph.png",
            "/img/triumph/splash-triumph.png",
            "T",
            "TRIUMPH"
        ),
        MAN: new Montadora(TipoMontadora.MAN, "MAN", "/img/vwco/logo-vwco.png", "/img/vwco/logo-vwco.png", "MA", "MAN"),
        BAJAJ: new Montadora(
            TipoMontadora.BAJAJ,
            "BAJAJ",
            "/img/bajaj/logo-bajaj.png",
            "/img/bajaj/logo-bajaj.png",
            "BA",
            "BAJAJ"
        )
    };

    static getByTipo(tipo: string): Montadora {
        return this.MONTADORAS[tipo];
    }
}
