import { Box, Typography } from "@material-ui/core";
import { Divider } from "@tecsinapse/ui-kit";
import React, { ReactNode } from "react";
import BoxCard from "ui/components/BoxCard/BoxCard";
import ConfiguracaoClipping from "ui/components/ConfiguracaoClipping/ConfiguracaoClipping";
import { ConfiguracaoClippingProps } from "ui/components/ConfiguracaoClipping/ConfiguracaoClippingProps";
import Filtro from "ui/components/Filtro/Filtro";
import FormatoDadosRelatorio, {
    FormatoDadosRelatorioProps
} from "ui/components/FormatoDadosRelatorio/FormatoDadosRelatorio";
import UltimaDataEmplacamento from "ui/components/ViewLayout/UltimaDataEmplacamento";
import { FiltroProps } from "../Filtro/model";
import LoadingViewLayout from "./LoadingViewLayout";

export interface ViewLayoutProps {
    titulo?: string | String;
    descricao?: string | ReactNode;
    filtroProps?: FiltroProps;
    carregando?: boolean;
    fullScreenLoading?: boolean;
    clippingProps?: ConfiguracaoClippingProps;
    mostraUltimaDataEmplacamento?: boolean;
    mostraSomenteConteudo?: boolean;
    mostraConteudoEmBoxCard?: boolean;
    mostraFiltro?: boolean;
    formatoDadosRelatorioProps?: FormatoDadosRelatorioProps;
    acoesRodape?: ReactNode;
}

const ViewLayout: React.FC<ViewLayoutProps> = ({
    children,
    titulo,
    descricao,
    filtroProps,
    carregando = false,
    clippingProps,
    mostraUltimaDataEmplacamento = true,
    mostraSomenteConteudo = false,
    mostraConteudoEmBoxCard = true,
    mostraFiltro = true,
    formatoDadosRelatorioProps,
    acoesRodape,
    fullScreenLoading = true
}) => {
    const BoxContent = mostraConteudoEmBoxCard ? BoxCard : Box;
    const mostraCabecalhoConteudo = descricao || clippingProps || filtroProps || formatoDadosRelatorioProps;
    const marginBarraSuperiorRodape = mostraSomenteConteudo ? "0px" : "60px";

    return (
        <>
            <Box
                display="flex"
                flex={1}
                flexDirection="column"
                marginBottom={marginBarraSuperiorRodape}
                marginTop={marginBarraSuperiorRodape}>
                {mostraUltimaDataEmplacamento && <UltimaDataEmplacamento />}

                <Box position="relative" margin={mostraConteudoEmBoxCard ? 1 : 0}>
                    <LoadingViewLayout loading={carregando} fullScreenLoading={fullScreenLoading} />
                    <BoxContent id="boxContent" display="flex" flexDirection="column" minHeight="400px">
                        {mostraCabecalhoConteudo && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
                                <Box marginRight={1} display="flex">
                                    <Typography variant="h6">{titulo}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-evenly" alignItems="center">
                                    {clippingProps && (
                                        <Box marginRight={0.7}>
                                            <ConfiguracaoClipping {...clippingProps} />
                                        </Box>
                                    )}
                                    {filtroProps && mostraFiltro && (
                                        <Box marginRight={0.7}>
                                            <Filtro {...filtroProps} />
                                        </Box>
                                    )}
                                    {formatoDadosRelatorioProps && (
                                        <FormatoDadosRelatorio {...formatoDadosRelatorioProps} />
                                    )}
                                </Box>
                            </Box>
                        )}
                        {mostraCabecalhoConteudo && <Divider />}
                        <Box padding={0.3}>{children}</Box>
                        {acoesRodape && <Divider />}
                        {acoesRodape && acoesRodape}
                    </BoxContent>
                </Box>
            </Box>
        </>
    );
};

export default ViewLayout;
