import { makeStyles } from "@material-ui/core";

export const useFiltroStyles = makeStyles(theme => ({
    appBar: {
        position: "fixed",
        top: 0,
        zIndex: 1300,
        width: "100%"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    subItemHeader: {
        fontWeight: "bold",
        marginBottom: 10
    },
    selectMenuItem: {
        marginTop: 10,
        marginBottom: 10
    }
}));
