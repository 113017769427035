import { Tab, Tabs } from "@material-ui/core";
import { DadosMarketShare, TipoAgrupamentoMarketShare, TODOS_TIPOS_DADOS, useLazyMarketShare } from "api";
import { TipoUsuario } from "api/enums/TipoUsuario";
import { AgrupamentoRenavamQuery, FiltrosSelecionados } from "api/model";
import { Usuario } from "api/model/Usuario";
import { State } from "api/redux/model";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { TipoFormatoDadosRelatorio } from "ui/components";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import TabelaMarketShare from "ui/components/TabelaMarketShare/TabelaMarketShare";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";

const criaAgrupamentoMarca = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comMarcaNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

const criaAgrupamentoGrupoModelo = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comGrupoModeloNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

const criaAgrupamentoModelo = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comModeloNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

const getDescricaoRelatorio = (usuario: Usuario) => {
    if (usuario.tipoUsuario === TipoUsuario.DEALER_GRUPO) {
        return "Ranking por marca e modelo considerando apenas os principais concorrentes.  Como padrão vem filtrado apenas a área de atuação do dealer.";
    }
    return "Ranking por marca e modelo considerando apenas os principais concorrentes.";
};

const atualizaDadosRelatorio = (setDadosRelatorio, tipoAgrupamento: TipoAgrupamentoMarketShare) => dadosMarketShare => {
    setDadosRelatorio(estadorAnterior => {
        const novoDadosRelatorio = new Map(estadorAnterior);
        novoDadosRelatorio.set(tipoAgrupamento, dadosMarketShare);
        return novoDadosRelatorio;
    });
};

const Segmento: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const appState = useSelector((state: State) => state.app);

    const [agrupamentoMarketShareSelecionado, setAgrupamentoMarketShareSelecionado] = useState(
        TipoAgrupamentoMarketShare.MARCA_NOME
    );
    const [dadosRelatorio, setDadosRelatorio] = useState<Map<TipoAgrupamentoMarketShare, DadosMarketShare>>(new Map());
    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltrosSelecionados>();
    const [formatoDadosRelatorio, setFormatoDadosRelatorio] = useState(TipoFormatoDadosRelatorio.ABSOLUTO);
    const agrupamentoMarca = useMemo(criaAgrupamentoMarca, []);
    const agrupamentoGrupoModelo = useMemo(criaAgrupamentoGrupoModelo, []);
    const agrupamentoModelo = useMemo(criaAgrupamentoModelo, []);

    const [carregaDadosMarketShareMarca, { carregandoDadosMarketShare: carregandoMarca }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MARCA_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoMarca,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.MARCA_NOME)
    });

    const [
        carregaDadosMarketShareGrupoModelo,
        { carregandoDadosMarketShare: carregandoGrupoModelo }
    ] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoGrupoModelo,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME)
    });

    const [carregaDadosMarketShareModelo, { carregandoDadosMarketShare: carregandoModelo }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MODELO_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoModelo,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.MODELO_NOME)
    });

    useEffect(() => {
        if (filtro) {
            setFiltrosSelecionados(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filtrosSelecionados) {
            setDadosRelatorio(new Map());
        }
    }, [filtrosSelecionados]);

    useEffect(() => {
        if (filtrosSelecionados && !dadosRelatorio.get(agrupamentoMarketShareSelecionado)) {
            if (agrupamentoMarketShareSelecionado === TipoAgrupamentoMarketShare.MARCA_NOME) {
                carregaDadosMarketShareMarca(filtrosSelecionados);
            } else if (agrupamentoMarketShareSelecionado === TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME) {
                carregaDadosMarketShareGrupoModelo(filtrosSelecionados);
            } else if (agrupamentoMarketShareSelecionado === TipoAgrupamentoMarketShare.MODELO_NOME) {
                carregaDadosMarketShareModelo(filtrosSelecionados);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtrosSelecionados, agrupamentoMarketShareSelecionado, dadosRelatorio]);

    return (
        <ViewLayout
            titulo="Segmento"
            descricao={getDescricaoRelatorio(appState.usuarioLogado)}
            carregando={carregandoMarca || carregandoGrupoModelo || carregandoModelo}
            filtroProps={{
                periodo: false,
                aplicarFiltro: setFiltrosSelecionados
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.SEGMENTS,
                filtrosSelecionados
            }}
            formatoDadosRelatorioProps={{
                onSelect: setFormatoDadosRelatorio,
                mediaProjecao: false
            }}
            {...viewLayoutProps}>
            <Tabs
                centered={true}
                value={agrupamentoMarketShareSelecionado}
                onChange={(event, agrupamentoMarketShare) =>
                    setAgrupamentoMarketShareSelecionado(agrupamentoMarketShare)
                }
                aria-label="disabled tabs example"
                variant="fullWidth">
                <Tab label="Marca" value={TipoAgrupamentoMarketShare.MARCA_NOME} />
                <Tab label="Grupo Modelo" value={TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME} />
                <Tab label="Modelo" value={TipoAgrupamentoMarketShare.MODELO_NOME} />
            </Tabs>
            <TabelaMarketShare
                dadosMarketShare={dadosRelatorio.get(agrupamentoMarketShareSelecionado)}
                quantidadeRegistros={30}
                formatoDadosRelatorio={formatoDadosRelatorio}
                dataReferencia={filtrosSelecionados ? filtrosSelecionados.dataFim : ""}
            />
        </ViewLayout>
    );
};

export default Segmento;
