import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { mdiArrowDown, mdiArrowUp, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@tecsinapse/ui-kit";
import { FiltrosSelecionados, getConsultaClippingsDoUsuario, Montadora, Montadoras, TipoAcaoApp } from "api";
import { State } from "api/redux/model";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TipoDataClipping, TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import { ConfiguracaoClippingType } from "ui/components/ConfiguracaoClipping/model/ConfiguracaoClippingType";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import {
    ajustaDataFiltro,
    ajustaPosicao,
    ajustarPosicaoItens,
    atualizaClippingEditado,
    getComponentTypeByTipoRelatorio,
    removerClipping
} from "ui/views/Clipping/funcoesClipping";
import {
    ajustarPosicaoRelatorioNoClipping,
    removerClippingItem
} from "ui/views/Clipping/queriesEMutations/clippingMutations";
import { marcarNotificacaoComoLida } from "ui/views/Notificacoes/mutations";

const AcoesCard = ({ item, removeRelatorio, alteraPosicao, dadosClipping, setItensClipping }) => {
    return (
        <div
            style={{
                padding: "8px 15px",
                justifyContent: "space-between",
                display: "flex"
            }}>
            <IconButton onClick={id => ajustarPosicaoItens(item, true, alteraPosicao, dadosClipping, setItensClipping)}>
                <Icon path={mdiArrowUp} size={1} />
            </IconButton>
            <IconButton
                onClick={id => ajustarPosicaoItens(item, false, alteraPosicao, dadosClipping, setItensClipping)}>
                <Icon path={mdiArrowDown} size={1} />
            </IconButton>
            <IconButton onClick={id => removerClipping(item, removeRelatorio, dadosClipping, setItensClipping)}>
                <Icon path={mdiDelete} size={1} />
            </IconButton>
        </div>
    );
};

const Clipping = () => {
    const appState = useSelector((state: State) => state);
    const dispatch = useDispatch();
    const { dataReferencia, montadora, idNotificacao } = useParams();
    const [itensClipping, setItensClipping] = useState<Array<ConfiguracaoClippingType>>([]);

    const dataClipping: string =
        dataReferencia || DateTime.fromFormat(appState.app.ultimaDataEmplacamento, "dd/MM/yyyy").toFormat("yyyy-MM-dd");

    if (montadora && montadora !== appState.app.marcaSelecionada) {
        dispatch({ type: TipoAcaoApp.SELECT_BRAND, data: montadora });
    }

    const [carregaClippingsUsuario, { data: dadosClippingUsuario }] = useLazyQuery(getConsultaClippingsDoUsuario(), {
        fetchPolicy: "network-only"
    });
    const [removerRelatorio] = useMutation(removerClippingItem());
    const [alterarPosicaoRelatorio] = useMutation(ajustarPosicaoRelatorioNoClipping());
    const [mutationMarcarNotificacaoComoLida] = useMutation(marcarNotificacaoComoLida, {
        variables: { id: idNotificacao }
    });

    useEffect(() => {
        if (idNotificacao) {
            mutationMarcarNotificacaoComoLida()
                .then(result => result.data && result.data["marcarNotificacaoComoLida"])
                .then(marcouComoLida => {
                    if (marcouComoLida) {
                        dispatch({
                            type: TipoAcaoApp.DECREMENTA_NOTIFICACAO_NAO_LIDA,
                            data: {}
                        });
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        carregaClippingsUsuario({
            variables: { email: appState.app.usuarioLogado.email }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.app.marcaSelecionada]);

    useEffect(() => {
        if (dadosClippingUsuario && dadosClippingUsuario["clippingDoUsuario"]) {
            let montadora = Montadoras.getByTipo(appState.app.marcaSelecionada);
            let dadosClipping: Array<ConfiguracaoClippingType> = [];
            dadosClippingUsuario["clippingDoUsuario"].forEach(dado => {
                let id: number = dado["id"];
                let descricao: string = dado["descricao"];
                let ajusteData: TipoDataClipping = dado["ajusteData"];
                let tipoRelatorio: TipoRelatorioClipping = dado["tipoRelatorio"];
                let posicao: number = dado["posicao"];

                let filtro: FiltrosSelecionados = FiltrosSelecionados.fromSelecionados(dado["filtro"]);
                filtro.dataInicio = dataClipping;
                filtro.dataFim = dataClipping;
                filtro.marcaDaMontadora = appState.filtro.marcaDaMontadora;

                filtro.agrupamentoMarcasDisponiveis = appState.filtro.agrupamentosMarcasDisponiveis;

                if (tipoRelatorio === TipoRelatorioClipping.SUBSEGMENTS) {
                    filtro.segmentosDisponiveis = appState.filtro.segmentosDisponiveis;
                }
                let montadoraClipping: Montadora = Montadoras.getByTipo((filtro.montadora || montadora).toString());
                if (montadora.enumName === montadoraClipping.enumName) {
                    ajustaDataFiltro(filtro, ajusteData, tipoRelatorio);
                    dadosClipping.push({ id, descricao, ajusteData, tipoRelatorio, filtro, email: "", posicao });
                }
            });

            ajustaPosicao(dadosClipping, setItensClipping);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosClippingUsuario]);

    return (
        <ViewLayout titulo="Clipping" mostraConteudoEmBoxCard={false}>
            {itensClipping.map(item => {
                const Component = getComponentTypeByTipoRelatorio(item.tipoRelatorio);
                return (
                    <Component
                        key={item.id}
                        viewLayoutProps={{
                            titulo: item.descricao,
                            mostraSomenteConteudo: true,
                            mostraUltimaDataEmplacamento: false,
                            mostraFiltro: false,
                            fullScreenLoading: false,
                            clippingProps: {
                                tipoRelatorio: item.tipoRelatorio,
                                edicao: true,
                                descricaoClipping: item.descricao,
                                idClipping: item.id,
                                aposEditar: atualizaClippingEditado(itensClipping, setItensClipping)
                            },
                            acoesRodape: (
                                <AcoesCard
                                    item={item}
                                    removeRelatorio={removerRelatorio}
                                    alteraPosicao={alterarPosicaoRelatorio}
                                    dadosClipping={itensClipping}
                                    setItensClipping={setItensClipping}
                                />
                            )
                        }}
                        filtro={item.filtro}
                    />
                );
            })}
        </ViewLayout>
    );
};

export default Clipping;
