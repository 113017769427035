import CssBaseline from "@material-ui/core/CssBaseline";
import { PickersProvider } from "@tecsinapse/pickers/build/Picker/PickersProvider";
import { init } from "@tecsinapse/react-boilerplate/build/index";
import { Providers } from "@tecsinapse/react-boilerplate/build/Providers";
import ThemeProvider from "@tecsinapse/ui-kit/build/ThemeProvider";
import store from "api/redux/store";
import App from "app/App";
import Highcharts from "highcharts";
import { Settings } from "luxon";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import SplashScreen from "ui/components/SplashScreen/SplashScreen";
import { getProperties } from "./api";
import { getClientConfig } from "./config/clientConfig";
import { publicRoutes } from "./routes/Routes";
import { ServerOfflineScreen } from "./ui/components/ServerOfflineScreen/ServerOfflineScreen";

Settings.defaultLocale = "pt-br";

Highcharts.setOptions({
    lang: {
        noData: "Não há dados para exibição",
        loading: "Carregando informações..."
    }
});

const isDev = process.env.REACT_APP_ENV === "development";
const isProduction = process.env.REACT_APP_ENV === "production";

export const showGlobalSplashScreen = client => {
    ReactDOM.unmountComponentAtNode(document.getElementById("splash_screen"));
    ReactDOM.render(
        <ThemeProvider variant="orange">
            <SplashScreen client={client} />
        </ThemeProvider>,
        document.getElementById("splash_screen")
    );
};

export const hideGlobalSplashScreen = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("splash_screen"));
};

(async function initApp() {
    try {
        const clientConfig = getClientConfig(window.location.origin, process.env.REACT_APP_ENV);

        showGlobalSplashScreen(clientConfig.client);

        const appProperties = await getProperties(clientConfig.url);
        const { keycloak, theme, analyticsCodeProd, hotJarCode } = appProperties;

        init({
            analyticsCode: isProduction ? analyticsCodeProd : null,
            hotjarId: isProduction ? hotJarCode : null,
            sentryOptions: null,
            reduxOptions: {},
            apolloOptions: {
                offlineApolloCacheOptions: null,
                uri: `${clientConfig.url}/rest/graphql/renavan`,
                connectToDevTools: isDev
            },
            keycloakOptions: {
                ignoreStandaloneLoginFlow: true,
                keycloakConfig: keycloak,
                logoutFunction: () => {},
                publicUrls: publicRoutes
            },
            renderFunction: async props => {
                const { keycloak } = props;
                hideGlobalSplashScreen();
                ReactDOM.render(
                    <Providers themeVariant={theme} {...props}>
                        <Provider store={store}>
                            <PickersProvider>
                                <CssBaseline />
                                <BrowserRouter>
                                    <App clientConfig={clientConfig} properties={appProperties} keycloak={keycloak} />
                                </BrowserRouter>
                            </PickersProvider>
                        </Provider>
                    </Providers>,
                    document.getElementById("root")
                );
            }
        });
    } catch (e) {
        hideGlobalSplashScreen();
        console.error(e);
        ReactDOM.render(<ServerOfflineScreen />, document.getElementById("root"));
    }
})();

// serviceWorker.unregister();
