import { Tab, Tabs } from "@material-ui/core";
import {
    AgrupamentoRenavamQuery,
    DadosMarketShare,
    FiltrosSelecionados,
    TipoAgrupamentoMarketShare,
    TODOS_TIPOS_DADOS,
    useLazyMarketShare
} from "api";
import { TipoUsuario } from "api/enums/TipoUsuario";
import { Usuario } from "api/model/Usuario";
import { State } from "api/redux/model";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { TipoFormatoDadosRelatorio } from "ui/components";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import TabelaMarketShare from "ui/components/TabelaMarketShare/TabelaMarketShare";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";

const getDescricaoRelatorio = (usuario: Usuario) => {
    if (usuario.tipoUsuario === TipoUsuario.DEALER_GRUPO) {
        return "Ranking em cada um dos subsegmentos de atuação da marca. Como padrão vem filtrado apenas a área de atuação do dealer.";
    }
    return "Ranking em cada um dos subsegmentos de atuação da marca.";
};

const criaAgrupamentoGrupoModelo = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comGrupoModeloNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

const criaAgrupamentoModelo = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comModeloNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

const atualizaDadosRelatorio = (setDadosRelatorio, tipoAgrupamento: TipoAgrupamentoMarketShare, codigoSegmentoSelecionado: string | String) => dadosMarketShare => {
    setDadosRelatorio((estadorAnterior: Map<TipoAgrupamentoMarketShare, Map<String | string, DadosMarketShare>>) => {
        const novoDadosRelatorio = new Map(estadorAnterior);
        let dadosPorSegmento = novoDadosRelatorio.get(tipoAgrupamento);

        if (!dadosPorSegmento) {
            dadosPorSegmento = new Map<string | String, DadosMarketShare>();
        }
        dadosPorSegmento.set(codigoSegmentoSelecionado, dadosMarketShare);
        
        novoDadosRelatorio.set(tipoAgrupamento, dadosPorSegmento);
        return novoDadosRelatorio;
    });
};

const SubSegmento: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const appState = useSelector((state: State) => state.app);
    const [agrupamentoMarketShareSelecionado, setAgrupamentoMarketShareSelecionado] = useState(
        TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME
    );
    const [dadosRelatorio, setDadosRelatorio] = useState<Map<TipoAgrupamentoMarketShare, Map<String | string, DadosMarketShare>>>(new Map());
    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltrosSelecionados>();
    const [formatoDadosRelatorio, setFormatoDadosRelatorio] = useState(TipoFormatoDadosRelatorio.ABSOLUTO);
    const [codigoSegmentoSelecionado, setCodigoSegmentoSelecionado] = useState<string | String>("");
    const [tabsSegmentos, setTabsSegmentos] = useState<JSX.Element[]>();
    const agrupamentoGrupoModelo = useMemo(criaAgrupamentoGrupoModelo, []);
    const agrupamentoModelo = useMemo(criaAgrupamentoModelo, []);

    const [
        carregaDadosMarketShareGrupoModelo,
        { carregandoDadosMarketShare: carregandoGrupoModelo }
    ] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoGrupoModelo,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME, codigoSegmentoSelecionado)
    });

    const [carregaDadosMarketShareModelo, { carregandoDadosMarketShare: carregandoModelo }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MODELO_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoModelo,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.MODELO_NOME, codigoSegmentoSelecionado)
    });

    useEffect(() => {
        if (filtro) {
            setFiltrosSelecionados(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filtrosSelecionados) {
            setDadosRelatorio(new Map());
            setCodigoSegmentoSelecionado(filtrosSelecionados.segmentosSelecionados[0]);
            setTabsSegmentos(
                filtrosSelecionados.obtemSegmentosSelecionadosAsSelectOptions().map(opcaoFiltro => {
                    return <Tab key={opcaoFiltro.value} label={opcaoFiltro.label} value={opcaoFiltro.value} />;
                })
            );
        }
    }, [filtrosSelecionados]);

    useEffect(() => {
        if (filtrosSelecionados && codigoSegmentoSelecionado) {
            let filtroClonado: FiltrosSelecionados = filtrosSelecionados.clone();
            filtroClonado.segmentosSelecionados = [codigoSegmentoSelecionado];

            if (agrupamentoMarketShareSelecionado === TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME) {
                if (!dadosRelatorio.get(agrupamentoMarketShareSelecionado)?.get(codigoSegmentoSelecionado)) {
                    carregaDadosMarketShareGrupoModelo(filtroClonado);
                }
            } else if (agrupamentoMarketShareSelecionado === TipoAgrupamentoMarketShare.MODELO_NOME) {
                if (!dadosRelatorio.get(agrupamentoMarketShareSelecionado)?.get(codigoSegmentoSelecionado)) {
                    carregaDadosMarketShareModelo(filtroClonado);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtrosSelecionados, agrupamentoMarketShareSelecionado, codigoSegmentoSelecionado, dadosRelatorio]);

    return (
        <ViewLayout
            titulo="SubSegmento"
            descricao={getDescricaoRelatorio(appState.usuarioLogado)}
            carregando={carregandoGrupoModelo || carregandoModelo}
            filtroProps={{
                periodo: false,
                aplicarFiltro: setFiltrosSelecionados
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.SUBSEGMENTS,
                filtrosSelecionados
            }}
            formatoDadosRelatorioProps={{
                onSelect: setFormatoDadosRelatorio,
                mediaProjecao: false
            }}
            {...viewLayoutProps}>
            <Tabs
                centered={true}
                value={agrupamentoMarketShareSelecionado}
                onChange={(event, agrupamentoMarketShare) =>
                    setAgrupamentoMarketShareSelecionado(agrupamentoMarketShare)
                }
                aria-label="disabled tabs example"
                variant="fullWidth">
                <Tab label="Grupo Modelo" value={TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME} />
                <Tab label="Modelo" value={TipoAgrupamentoMarketShare.MODELO_NOME} />
            </Tabs>
            <Tabs
                value={codigoSegmentoSelecionado}
                onChange={(event, codigoSegmento) =>
                    setCodigoSegmentoSelecionado(codigoSegmento)
                }
                variant="scrollable">
                {tabsSegmentos}
            </Tabs>
            <TabelaMarketShare
                dadosMarketShare={dadosRelatorio.get(agrupamentoMarketShareSelecionado)?.get(codigoSegmentoSelecionado)}
                quantidadeRegistros={30}
                formatoDadosRelatorio={formatoDadosRelatorio}
                dataReferencia={filtrosSelecionados ? filtrosSelecionados.dataFim : ""}
            />
        </ViewLayout>
    );
};

export default SubSegmento;
