import { useMutation } from "@apollo/react-hooks";
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Switch } from "@material-ui/core";
import { mdiAccountCircle, mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";
import { withKeycloak } from "@tecsinapse/react-boilerplate/build/keycloak/withKeycloak";
import { Divider } from "@tecsinapse/ui-kit";
import { State } from "api/redux/model";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { TipoNotificacaoApp } from "ui/views";
import {
    adicionarTipoNotificacaoPush,
    bloquearPushNotification,
    permitirPushSubscription,
    removerTipoNotificacaoPush
} from "ui/views/Configuracoes/mutations";
import { ativaOuDesativaNotificacaoClipping, permitirNotificacoesNoBrowser } from "ui/views/Configuracoes/util";

const suportaNotificacoes = "Notification" in window;

const useStyles = makeStyles(() => ({
    logoTec: {
        width: "100%",
        padding: 10,
        textAlign: "center"
    }
}));

const Configuracoes = ({ logout }) => {
    const classes = useStyles();
    const appState = useSelector((app: State) => app.app);
    const [permitirPush] = useMutation(permitirPushSubscription());
    const [bloquearPushNoBrowser] = useMutation(bloquearPushNotification());
    const [adicionarTipoNotificacao] = useMutation(adicionarTipoNotificacaoPush());
    const [removerTipoNotificacao] = useMutation(removerTipoNotificacaoPush());
    const dispatch = useDispatch();

    return (
        <ViewLayout titulo="Configuraçẽs" mostraConteudoEmBoxCard={false} mostraUltimaDataEmplacamento={false}>
            <List>
                <ListItem disabled={!suportaNotificacoes}>
                    <ListItemIcon>
                        <Switch
                            disabled={!suportaNotificacoes}
                            checked={appState.configuracoes.notificacoesAtivas}
                            onChange={permitirNotificacoesNoBrowser(
                                permitirPush,
                                bloquearPushNoBrowser,
                                appState.usuarioLogado.email,
                                appState.appProperties.vapidPublicKey,
                                dispatch
                            )}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Ativar notificações" />
                </ListItem>
                <Divider variant="solid" />
                <ListItem disabled={!suportaNotificacoes}>
                    <ListItemIcon>
                        <Switch
                            disabled={!suportaNotificacoes}
                            checked={appState.configuracoes.tiposNotificoes.includes(TipoNotificacaoApp.CLIPPING)}
                            onChange={ativaOuDesativaNotificacaoClipping(
                                TipoNotificacaoApp.CLIPPING,
                                dispatch,
                                adicionarTipoNotificacao,
                                removerTipoNotificacao,
                                appState.usuarioLogado.email
                            )}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Atualizações Diárias (Clipping)" />
                </ListItem>
                <Divider variant="solid" />
                <ListItem button onClick={() => logout()}>
                    <ListItemIcon>
                        <Icon path={mdiAccountCircle} size={1} />
                    </ListItemIcon>
                    <ListItemText primary={appState.usuarioLogado.nome} secondary="Deslogar" />
                </ListItem>
                <Divider variant="solid" />
                <ListItem>
                    <ListItemIcon>
                        <Icon path={mdiInformation} size={1} />
                    </ListItemIcon>
                    <ListItemText primary="Versão do aplicativo" secondary="v2.0.0" />
                </ListItem>
                <Divider variant="solid" />
                <ListItem button onClick={() => (window.location = appState.urlServer)}>
                    <ListItemIcon>
                        <Icon path={mdiInformation} size={1} />
                    </ListItemIcon>
                    <ListItemText primary="Emplacar WEB" secondary={appState.urlServer} />
                </ListItem>
                <Divider variant="solid" />
            </List>
            <div className={classes.logoTec}>
                <img
                    src={`${process.env.PUBLIC_URL}/img/powered-by-ts.png`}
                    alt="tecsinapse"
                    style={{ maxHeight: 36 }}
                />
            </div>
        </ViewLayout>
    );
};

export default withKeycloak(Configuracoes);
