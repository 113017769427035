import { BottomNavigation, BottomNavigationAction, Box } from "@material-ui/core";
import { mdiChartBar, mdiEye, mdiSettings } from "@mdi/js";
import Icon from "@mdi/react";
import { Divider } from "@tecsinapse/ui-kit";
import React, { useState } from "react";
import { useHistory } from "react-router";

const MenuApp = () => {
    const history = useHistory();
    const [tabSelecionada, setTabSelecionada] = useState(history.location.pathname);

    return (
        <Box position="fixed" width="100%" bottom={0} zIndex={1100}>
            <Divider variant="solid" />
            <BottomNavigation
                value={tabSelecionada}
                onChange={(event, value) => {
                    history.replace(value);
                    setTabSelecionada(value);
                }}
                showLabels>
                <BottomNavigationAction label="Relatórios" value="/" icon={<Icon path={mdiChartBar} size={1} />} />
                <BottomNavigationAction label="Clipping" value="/clipping" icon={<Icon path={mdiEye} size={1} />} />
                <BottomNavigationAction
                    label="Configurações"
                    value="/configuracoes"
                    icon={<Icon path={mdiSettings} size={1} />}
                />
            </BottomNavigation>
        </Box>
    );
};

export default MenuApp;
