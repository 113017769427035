export class DadosMarketShareItem {
    corMarca?: String;

    volumeDia?: number;
    volumeDiaPd?: number;

    volumeMtd?: number;
    volumeMtdPm?: number;
    volumeMtdPy?: number;

    volumeYtd?: number;
    volumeYtdPm?: number;
    volumeYtdPy?: number;
}
