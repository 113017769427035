import { Box, Tab, Tabs } from "@material-ui/core";
import {
    DadosMarketShare,
    FiltrosSelecionados,
    TipoAgrupamentoMarketShare,
    TODOS_TIPOS_DADOS,
    useLazyMarketShare
} from "api";
import { State } from "api/redux/model";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { TipoFormatoDadosRelatorio } from "ui/components";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import TabelaMarketShare from "ui/components/TabelaMarketShare/TabelaMarketShare";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { getDescricaoRelatorio } from "ui/views/Monthly/util/funcoesMothly";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";
import {
    criaAgrupamentoGrupoModelo,
    criaAgrupamentoMarca,
    criaAgrupamentoModelo,
    customizaFiltro
} from "ui/views/TotalIndustry/util/funcoesTotalIndustry";

const atualizaDadosRelatorio = (setDadosRelatorio, tipoAgrupamento: TipoAgrupamentoMarketShare) => dadosMarketShare => {
    setDadosRelatorio(estadorAnterior => {
        const novoDadosRelatorio = new Map(estadorAnterior);
        novoDadosRelatorio.set(tipoAgrupamento, dadosMarketShare);
        return novoDadosRelatorio;
    });
};

const TotalIndustry: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const appState = useSelector((state: State) => state.app);
    const [agrupamentoMarketShare, setAgrupamentoMarketShare] = useState(TipoAgrupamentoMarketShare.MARCA_NOME);
    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltrosSelecionados>();
    const [formatoDadosRelatorio, setFormatoDadosRelatorio] = useState(TipoFormatoDadosRelatorio.ABSOLUTO);
    const [dadosRelatorio, setDadosRelatorio] = useState<Map<TipoAgrupamentoMarketShare, DadosMarketShare>>(new Map());
    const agrupamentoMarca = useMemo(criaAgrupamentoMarca, []);
    const agrupamentoGrupoModelo = useMemo(criaAgrupamentoGrupoModelo, []);
    const agrupamentoModelo = useMemo(criaAgrupamentoModelo, []);
    const descricaoRelatorio = useMemo(
        () => getDescricaoRelatorio(appState.usuarioLogado),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [carregaDadosMarca, { carregandoDadosMarketShare: carregandoDadosMarca }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MARCA_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoMarca,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.MARCA_NOME)
    });

    const [carregaDadosGrupoModelo, { carregandoDadosMarketShare: carregandoDadosGrupoModelo }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoGrupoModelo,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME)
    });

    const [carregaDadosModelo, { carregandoDadosMarketShare: carregandoDadosModelo }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MODELO_NOME,
        dadosDisponiveisNoMarketShare: TODOS_TIPOS_DADOS,
        agrupamentoRenavam: agrupamentoModelo,
        aposCarregar: atualizaDadosRelatorio(setDadosRelatorio, TipoAgrupamentoMarketShare.MODELO_NOME)
    });

    useEffect(() => {
        if (filtro) {
            setFiltrosSelecionados(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filtrosSelecionados) {
            setDadosRelatorio(new Map());
        }
    }, [filtrosSelecionados]);

    useEffect(() => {
        if (filtrosSelecionados && !dadosRelatorio.get(agrupamentoMarketShare)) {
            if (agrupamentoMarketShare === TipoAgrupamentoMarketShare.MARCA_NOME) {
                carregaDadosMarca(filtrosSelecionados);
            }
            if (agrupamentoMarketShare === TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME) {
                carregaDadosGrupoModelo(filtrosSelecionados);
            }
            if (agrupamentoMarketShare === TipoAgrupamentoMarketShare.MODELO_NOME) {
                carregaDadosModelo(filtrosSelecionados);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtrosSelecionados, agrupamentoMarketShare, dadosRelatorio]);

    return (
        <ViewLayout
            carregando={carregandoDadosMarca || carregandoDadosGrupoModelo || carregandoDadosModelo}
            titulo="Total Industry"
            descricao={descricaoRelatorio}
            filtroProps={{
                periodo: false,
                customizaFiltro: customizaFiltro,
                aplicarFiltro: setFiltrosSelecionados
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.TOTAL_INDUSTRY,
                filtrosSelecionados
            }}
            formatoDadosRelatorioProps={{
                onSelect: setFormatoDadosRelatorio,
                mediaProjecao: false
            }}
            {...viewLayoutProps}>
            <Box>
                <Box>
                    <Tabs
                        value={agrupamentoMarketShare}
                        onChange={(event, agrupamentoMarketShare) => setAgrupamentoMarketShare(agrupamentoMarketShare)}
                        aria-label="agrupamento tabs"
                        scrollButtons="auto"
                        variant="fullWidth">
                        <Tab label="Marcas" value={TipoAgrupamentoMarketShare.MARCA_NOME} fullWidth={true} />
                        <Tab
                            label="Grupo Modelo"
                            value={TipoAgrupamentoMarketShare.GRUPO_MODELO_NOME}
                            fullWidth={true}
                        />
                        <Tab label="Modelos" value={TipoAgrupamentoMarketShare.MODELO_NOME} fullWidth={true} />
                    </Tabs>
                </Box>
                <Box fontSize={12}>
                    <TabelaMarketShare
                        dadosMarketShare={dadosRelatorio.get(agrupamentoMarketShare)}
                        formatoDadosRelatorio={formatoDadosRelatorio}
                        dataReferencia={filtrosSelecionados ? filtrosSelecionados.dataFim : ""}
                    />
                </Box>
            </Box>
        </ViewLayout>
    );
};

export default TotalIndustry;
