import gql from "graphql-tag";

export const removerClippingItem = () => {
    return gql`
        mutation removerClippingItem($id: ID!) {
            removerClippingItem(id: $id)
        }
    `;
};

export const ajustarPosicaoRelatorioNoClipping = () => {
    return gql`
        mutation ajustarPosicaoRelatorioNoClipping($id: ID!, $subir: Boolean!) {
            ajustarPosicaoRelatorioNoClipping(id: $id, subir: $subir)
        }
    `;
};
