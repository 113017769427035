export enum TipoMontadora {
    BMW,
    BMW_MOTORRAD,
    MINI,
    AUDI,
    HARLEY,
    TRIUMPH,
    JAGUAR,
    LAND_ROVER,
    DUCATI,
    MAN,
    BAJAJ
}
