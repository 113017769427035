import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { State } from "api/redux/model";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SubmitButton } from "ui/components";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { Notificacao } from "ui/views/Notificacoes/model/Notificacao";
import NotificacaoItem from "ui/views/Notificacoes/NotificacaoItem";
import { notificacoesUsuarioQuery } from "ui/views/Notificacoes/queries";

interface ResultadoConsultaNotificacoes {
    buscaNotificacoesUsuario: Notificacao[];
    totalNotificacoesUsuario: number;
}

const Notificacoes = () => {
    const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
    const { usuarioLogado, marcaSelecionada, notificacoesNaoLidas } = useSelector((state: State) => state.app);

    const { data, refetch, fetchMore, loading } = useQuery<ResultadoConsultaNotificacoes>(notificacoesUsuarioQuery, {
        variables: { email: usuarioLogado.email, limit: 10, offset: 0, montadora: marcaSelecionada },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (data && data.buscaNotificacoesUsuario) {
            setNotificacoes(data.buscaNotificacoesUsuario);
        }
    }, [data]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificacoesNaoLidas]);

    const totalNotificacoesUsuario = data && data.totalNotificacoesUsuario ? data.totalNotificacoesUsuario : 0;
    const esconderBotaoCarregarMais =
        notificacoes.length === 0 || notificacoes.length === totalNotificacoesUsuario || totalNotificacoesUsuario <= 20;

    return (
        <ViewLayout titulo="Notificações" mostraConteudoEmBoxCard={false} mostraUltimaDataEmplacamento={false}>
            <Grid container spacing={1}>
                {notificacoes.map(notificacao => {
                    return (
                        <Grid key={notificacao.id} item xs={12}>
                            <NotificacaoItem
                                key={notificacao.id}
                                notificacao={notificacao}
                                refetchNotificacoes={refetch}
                            />
                        </Grid>
                    );
                })}
                <Grid item xs={12} hidden={esconderBotaoCarregarMais}>
                    <SubmitButton
                        isSubmitting={loading}
                        onClick={() => {
                            fetchMore({
                                variables: { offset: notificacoes.length },
                                updateQuery: (resultadoAnterior, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return resultadoAnterior;
                                    return {
                                        ...resultadoAnterior,
                                        buscaNotificacoesUsuario: [
                                            ...resultadoAnterior.buscaNotificacoesUsuario,
                                            ...fetchMoreResult.buscaNotificacoesUsuario
                                        ]
                                    };
                                }
                            });
                        }}>
                        Carregar mais...
                    </SubmitButton>
                </Grid>
            </Grid>
        </ViewLayout>
    );
};

export default Notificacoes;
