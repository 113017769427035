import { Regiao, Regioes } from "./Regiao";

export interface Estado {
    sigla: string;
    descricao: string;
    regiao: Regiao;
}

export class Estados {
    static AC: Estado = {
        sigla: "AC",
        descricao: "Acre",
        regiao: Regioes.NORTE
    };
    static AL: Estado = {
        sigla: "AL",
        descricao: "Alagoas",
        regiao: Regioes.NORDESTE
    };
    static AM: Estado = {
        sigla: "AM",
        descricao: "Amazonas",
        regiao: Regioes.NORTE
    };
    static AP: Estado = {
        sigla: "AP",
        descricao: "Amapá",
        regiao: Regioes.NORTE
    };
    static BA: Estado = {
        sigla: "BA",
        descricao: "Bahia",
        regiao: Regioes.NORDESTE
    };
    static CE: Estado = {
        sigla: "CE",
        descricao: "Ceará",
        regiao: Regioes.NORDESTE
    };
    static DF: Estado = {
        sigla: "DF",
        descricao: "Distrito Federal",
        regiao: Regioes.CENTRO_OESTE
    };
    static ES: Estado = {
        sigla: "ES",
        descricao: "Espírito Santo",
        regiao: Regioes.SUDESTE
    };
    static GO: Estado = {
        sigla: "GO",
        descricao: "Goiás",
        regiao: Regioes.CENTRO_OESTE
    };
    static MA: Estado = {
        sigla: "MA",
        descricao: "Maranhão",
        regiao: Regioes.NORDESTE
    };
    static MG: Estado = {
        sigla: "MG",
        descricao: "Minas Gerais",
        regiao: Regioes.SUDESTE
    };
    static MS: Estado = {
        sigla: "MS",
        descricao: "Mato Grosso do Sul",
        regiao: Regioes.CENTRO_OESTE
    };
    static MT: Estado = {
        sigla: "MT",
        descricao: "Mato Grosso",
        regiao: Regioes.CENTRO_OESTE
    };
    static PB: Estado = {
        sigla: "PB",
        descricao: "Paraíba",
        regiao: Regioes.NORDESTE
    };
    static PA: Estado = {
        sigla: "PA",
        descricao: "Pará",
        regiao: Regioes.NORTE
    };
    static PE: Estado = {
        sigla: "PE",
        descricao: "Pernambuco",
        regiao: Regioes.NORDESTE
    };
    static PI: Estado = {
        sigla: "PI",
        descricao: "Piauí",
        regiao: Regioes.NORDESTE
    };
    static PR: Estado = {
        sigla: "PR",
        descricao: "Paraná",
        regiao: Regioes.SUL
    };
    static RJ: Estado = {
        sigla: "RJ",
        descricao: "Rio de Janeiro",
        regiao: Regioes.SUDESTE
    };
    static RN: Estado = {
        sigla: "RN",
        descricao: "Rio Grande do Norte",
        regiao: Regioes.NORDESTE
    };
    static RO: Estado = {
        sigla: "RO",
        descricao: "Rondônia",
        regiao: Regioes.NORTE
    };
    static RR: Estado = {
        sigla: "RR",
        descricao: "Roraima",
        regiao: Regioes.NORTE
    };
    static RS: Estado = {
        sigla: "RS",
        descricao: "Rio Grande do Sul",
        regiao: Regioes.SUL
    };
    static SC: Estado = {
        sigla: "SC",
        descricao: "Santa Catarina",
        regiao: Regioes.SUL
    };
    static SE: Estado = {
        sigla: "SE",
        descricao: "Sergipe",
        regiao: Regioes.NORDESTE
    };
    static SP: Estado = {
        sigla: "SP",
        descricao: "São Paulo",
        regiao: Regioes.SUDESTE
    };
    static TO: Estado = {
        sigla: "TO",
        descricao: "Tocantins",
        regiao: Regioes.NORTE
    };

    static getEstados(): Array<Estado> {
        return [
            this.AC,
            this.AL,
            this.AM,
            this.AP,
            this.BA,
            this.CE,
            this.DF,
            this.ES,
            this.GO,
            this.MA,
            this.MG,
            this.MS,
            this.MT,
            this.PB,
            this.PA,
            this.PE,
            this.PI,
            this.PR,
            this.RJ,
            this.RN,
            this.RO,
            this.RR,
            this.RS,
            this.SC,
            this.SE,
            this.SP,
            this.TO
        ];
    }
}
