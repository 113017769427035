import { Table } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CabecalhoTabelaMarketShare from "ui/components/TabelaMarketShare/CabecalhoTabelaMarketShare";
import ConteudoTabelaMarketShare from "ui/components/TabelaMarketShare/ConteudoTabelaMarketShare";
import {
    converteDadosParaLinhasTabela,
    criaColunasPorTipoVisao
} from "ui/components/TabelaMarketShare/util/funcoesTabelaMarketShare";
import { TipoFormatoDadosRelatorio } from "../FormatoDadosRelatorio";
import { TabelaMarketShareProps } from "./TabelaMarketShareProps";

const TabelaMarketShare: React.FC<TabelaMarketShareProps> = ({
    dadosMarketShare,
    quantidadeRegistros = 10,
    dataReferencia,
    formatoDadosRelatorio = TipoFormatoDadosRelatorio.ABSOLUTO
}) => {
    const [colunasPorTipoVisao, setColunasPorTipoVisao] = useState<Map<TipoFormatoDadosRelatorio, any[]>>(new Map());

    useEffect(() => {
        setColunasPorTipoVisao(criaColunasPorTipoVisao(dataReferencia));
    }, [dataReferencia]);

    return (
        <Table>
            <CabecalhoTabelaMarketShare colunas={colunasPorTipoVisao.get(formatoDadosRelatorio) || []} />
            <ConteudoTabelaMarketShare
                rows={converteDadosParaLinhasTabela(formatoDadosRelatorio, quantidadeRegistros, dadosMarketShare) || []}
            />
        </Table>
    );
};

export default TabelaMarketShare;
