export function criaGraficoBarra(mktShare): Highcharts.Options {
    const categories = mktShare && mktShare.categorias ? mktShare.categorias : [];
    const data = mktShare && mktShare.valoresGrafico ? mktShare.valoresGrafico : [];
    const colors = mktShare && mktShare.cores ? mktShare.cores : [];

    return {
        chart: {
            type: "bar"
        },
        title: {
            text: ""
        },
        subtitle: {
            text: `Total: ${mktShare.total}`
        },
        xAxis: {
            categories
        },
        yAxis: {
            title: {
                text: ""
            }
        },
        loading: {
            hideDuration: 100,
            showDuration: 100
        },
        series: [
            {
                type: "bar",
                name: "Quantidade",
                data
            }
        ],
        exporting: { enabled: false },
        tooltip: {
            pointFormat: "{point.y:.0f} / {point.percentage:.1f}%"
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    format: "{point.y:.0f} / {point.percentage:.1f}%"
                },
                colorByPoint: true,
                colors
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        }
    };
}
