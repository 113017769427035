import { Estados } from "api/enums";
import { FiltrosSelecionados } from "api/model";
import { group } from "api/util/objectFunctions";
import { TipoFormatoDadosRelatorio } from "ui/components/FormatoDadosRelatorio/model/TipoFormatoDadosRelatorio";
import { DadosRelatorioUfAnalysis } from "../model/DadosRelatorioUfAnalysis";

export function calculaEGeraGraficoUfAnalysis(
    dadosConsulta: any,
    tipoFormatoDadosRelatorio: TipoFormatoDadosRelatorio,
    filtro?: FiltrosSelecionados
) {
    if (!dadosConsulta) {
        return {title: {
            text: ""
        }};
    }
    let renavans = dadosConsulta["agrupamentoRenavam"];
    let renavansMarcaSelecionada = calculaQuantidadesRenavansMontadoraSelecionada(renavans, filtro);

    let dadosSerieQuantidadeEstado = renavansMarcaSelecionada.map(renavam => {
        return {
            y: renavam.quantidade,
            descricao: renavam.descricaoEstado
        };
    });

    let dadosSerieShareEstado = calculaSharesPorEstadoDaMarcaSelecionada(renavansMarcaSelecionada, renavans);
    let dadosSerieShareMediaNacional = calculaMediaNacionalDoShare(renavansMarcaSelecionada, renavans);
    let categorias = renavansMarcaSelecionada.map(renavam => renavam.estado);
    return atualizaGrafico(
        tipoFormatoDadosRelatorio,
        dadosSerieQuantidadeEstado,
        dadosSerieShareEstado,
        dadosSerieShareMediaNacional,
        categorias
    );
}

const atualizaGrafico = (
    tipoFormatoDadosRelatorio: TipoFormatoDadosRelatorio,
    seriesQuantidadeEstado: {}[],
    seriesShareEstado: number[],
    seriesShareMediaNacional: number[],
    categorias: string[]
) => {
    let series: (Highcharts.SeriesColumnOptions | Highcharts.SeriesSplineOptions)[] = [];

    if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
        series.push(criaSerieDeColunasParaEstados(seriesQuantidadeEstado));
    }
    if (tipoFormatoDadosRelatorio === TipoFormatoDadosRelatorio.PERCENTUAL) {
        series.push(criaSerieShareDeColunasParaEstados(seriesShareEstado));
        series.push(criaSerieParaShareDaMediaNacional(seriesShareMediaNacional));
    }

    return criaGraficoUfAnalysis(categorias, series, tipoFormatoDadosRelatorio);
};

const calculaQuantidadesRenavansMontadoraSelecionada = (
    renavans,
    filtro?: FiltrosSelecionados
): Array<DadosRelatorioUfAnalysis> => {
    const filterFunction = renavam => {
        const marcasDoAgrupamentoSelecionado = filtro?.getMarcasDosAgrupamentosMarcasSelecionados();
        if (marcasDoAgrupamentoSelecionado && marcasDoAgrupamentoSelecionado.length > 0) {
            return marcasDoAgrupamentoSelecionado.some(marca => marca.nome === renavam.marca_nome);
        }
        return renavam.marca_nome === filtro?.marcaDaMontadora?.nome;
    };

    let renavansBMW = renavans
        .slice()
        .filter(filterFunction)
        .map(renavam => {
            return {
                estado: renavam.cidade_estado,
                descricaoEstado: Estados[renavam.cidade_estado].descricao,
                quantidade: renavam.quantidade
            };
        });

    const keyFunction = renavam => {
        return {
            estado: renavam.estado,
            descricaoEstado: renavam.descricaoEstado
        };
    };

    return group(renavansBMW, keyFunction, ["quantidade"]).sort((r1, r2) => r2.quantidade - r1.quantidade);
};

const calculaSharesPorEstadoDaMarcaSelecionada = (
    renavansMarcaSelecionada: DadosRelatorioUfAnalysis[],
    renavans: any
) => {
    return renavansMarcaSelecionada.map(renavamMarcaSelecionada => {
        let totalEmplacamentoNoEstado = renavans
            .filter(renavam => renavam.cidade_estado === renavamMarcaSelecionada.estado)
            .map(renavam => renavam.quantidade)
            .reduce((anterior, atual) => anterior + atual);
        return (renavamMarcaSelecionada.quantidade * 100) / totalEmplacamentoNoEstado;
    });
};

const calculaMediaNacionalDoShare = (renavansMarcaSelecionada: DadosRelatorioUfAnalysis[], renavans: any) => {
    let totalGeral = renavans.map(renavam => renavam.quantidade).reduce((anterior, atual) => anterior + atual, 0);

    let totalMarcaSelecionada = renavansMarcaSelecionada
        .map(renavam => renavam.quantidade)
        .reduce((anterior, atual) => anterior + atual, 0);

    let mediaNacional = (totalMarcaSelecionada * 100) / totalGeral;

    return renavansMarcaSelecionada.map(reanavam => mediaNacional);
};

const criaSerieDeColunasParaEstados = (quantidades): Highcharts.SeriesColumnOptions => {
    return {
        type: "column",
        name: "Quantidade",
        yAxis: "qtdShare",
        data: quantidades,
        tooltip: {
            pointFormat:
                '<b>{point.descricao}</b><br/><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
        }
    };
};

const criaSerieShareDeColunasParaEstados = (quantidades): Highcharts.SeriesColumnOptions => {
    return {
        type: "column",
        name: "Share",
        yAxis: "qtdShare",
        data: quantidades,
        tooltip: {
            pointFormat:
                '<b>{point.descricao}</b><br/><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y: .1f} %</b><br/>'
        }
    };
};

const criaSerieParaShareDaMediaNacional = (medias: any[]): Highcharts.SeriesSplineOptions => {
    return {
        type: "spline",
        name: "Média Nacional",
        dashStyle: "ShortDash",
        yAxis: "qtdShare",
        data: medias,
        color: "#ff704d",
        marker: {
            enabled: false
        },
        tooltip: {
            valueDecimals: 1,
            valueSuffix: " %"
        }
    };
};

const criaGraficoUfAnalysis = (
    categorias: string[],
    series: (Highcharts.SeriesColumnOptions | Highcharts.SeriesSplineOptions)[],
    tipoFormato: TipoFormatoDadosRelatorio
): Highcharts.Options => {
    return {
        title: {
            text: ""
        },
        xAxis: {
            categories: categorias
        },
        yAxis: [
            {
                id: "qtdShare",
                labels: {
                    format: `{value} ${tipoFormato === TipoFormatoDadosRelatorio.ABSOLUTO ? "" : " %"}`
                },
                title: {
                    text: tipoFormato === TipoFormatoDadosRelatorio.ABSOLUTO ? "Quantidade" : "Share"
                }
            }
        ],
        tooltip: {
            shared: true,
            headerFormat: ""
        },
        exporting: { enabled: false },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        legend: {
            enabled: true
        },
        series,
        credits: {
            enabled: false
        }
    };
};
