export enum TipoRelatorioClipping {
    MARKET_SHARE = "MARKET_SHARE",
    TOTAL_INDUSTRY = "TOTAL_INDUSTRY",
    SEGMENTS = "SEGMENTS",
    SUBSEGMENTS = "SUBSEGMENTS",
    MONTADORA = "MONTADORA",
    MONTHLY = "MONTHLY",
    REGIONAL_ANALYSIS = "REGIONAL_ANALYSIS",
    UF_ANALYSIS = "UF_ANALYSIS"
}

export const getDescricaoTipoRelatorio = (tipoRelatorio: TipoRelatorioClipping) => {
    switch (tipoRelatorio) {
        case TipoRelatorioClipping.MARKET_SHARE:
            return "Market Share";
        case TipoRelatorioClipping.TOTAL_INDUSTRY:
            return "Total Industry";
        case TipoRelatorioClipping.SEGMENTS:
            return "Segments";
        case TipoRelatorioClipping.SUBSEGMENTS:
            return "SubSegments";
        case TipoRelatorioClipping.MONTADORA:
            return "Montadora";
        case TipoRelatorioClipping.MONTHLY:
            return "Monthly";
        case TipoRelatorioClipping.REGIONAL_ANALYSIS:
            return "Regional Analysis";
        case TipoRelatorioClipping.UF_ANALYSIS:
            return "UF Analysis";
        default:
            return "";
    }
};
