import { Box, LinearProgress, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    logo_magin: {
        [theme.breakpoints.down(820)]: {
            marginBottom: "20px"
        },
        [theme.breakpoints.down(550)]: {
            marginBottom: "50px"
        },
        [theme.breakpoints.up(820)]: {
            marginBottom: "50px"
        }
    },
    logo_audi: {
        width: "65%",

        [theme.breakpoints.up(550)]: {
            width: "35%"
        }
    },
    logo_bmw: {
        width: "40%",
        [theme.breakpoints.up(550)]: {
            width: "20%"
        }
    },
    logo_triumph: {
        width: "65%",

        [theme.breakpoints.up(550)]: {
            width: "35%"
        }
    },
    logo_vwco: {
        width: "65%",

        [theme.breakpoints.up(550)]: {
            width: "35%"
        }
    },
    logo: {
        width: "40%",

        [theme.breakpoints.up(550)]: {
            width: "20%"
        }
    },
    emplacar: {
        width: "80%",
        [theme.breakpoints.up(550)]: {
            width: "45%"
        }
    }
}));

const SplashScreen = ({ client }) => {
    const classes = useStyles();

    return (
        <>
            <Box position="fixed" top={0} left={0} width="100%">
                <LinearProgress />
            </Box>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    width: "100vw",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/${client}/bg-splash.jpg)`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%">
                    <img
                        src={`${process.env.PUBLIC_URL}/img/${client}/splash-${client}.png`}
                        alt="logo-splash"
                        className={clsx(classes[`logo_${client}`] || classes.logo, classes.logo_magin)}
                    />
                    <img
                        src={`${process.env.PUBLIC_URL}/img/emplacamento.png`}
                        alt="logo-emplacar"
                        className={classes.emplacar}
                    />
                </Box>
                <Box>
                    <img
                        src={`${process.env.PUBLIC_URL}/img/powered-by-ts.png`}
                        alt="powered-by-ts"
                        style={{ marginBottom: 10, maxHeight: 36 }}
                    />
                </Box>
            </div>
        </>
    );
};

export default SplashScreen;
