import { Box, Typography } from "@material-ui/core";
import { Select } from "@tecsinapse/ui-kit";
import { State } from "api/redux/model";
import React from "react";
import { useSelector } from "react-redux";
import { useFiltroStyles } from "ui/components/Filtro/css/FiltroStyle";
import { FiltrosSelecionados, getOpcaoFiltroAsSelectOptions } from "./model";

interface FiltroSegmentacaoProps {
    filtrosSelecionados: FiltrosSelecionados;
    updateState: Function;
}

const FiltroSegmentacao: React.FC<FiltroSegmentacaoProps> = ({ filtrosSelecionados, updateState }) => {
    const classes = useFiltroStyles();
    const labels = useSelector((state: State) => state.labels);

    return (
        <Box padding={1}>
            <Typography className={classes.subItemHeader}>Segmentação</Typography>
            <Select
                className={classes.selectMenuItem}
                value={filtrosSelecionados.tiposModelosSelecionados}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.tiposModelosDisponiveis)}
                variant="mobile"
                label={labels.filtro.tipoModelo}
                isMulti
                fullWidth
                onChange={updateState("tiposModelosSelecionados")}
            />
            <Select
                className={classes.selectMenuItem}
                value={filtrosSelecionados.conceitosSelecionados}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.conceitosDisponiveis)}
                variant="mobile"
                label={labels.filtro.conceito}
                isMulti
                fullWidth
                onChange={updateState("conceitosSelecionados")}
            />
            <Select
                className={classes.selectMenuItem}
                value={filtrosSelecionados.segmentosSelecionados}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.segmentosDisponiveis)}
                variant="mobile"
                label={labels.filtro.segmento}
                isMulti
                fullWidth
                onChange={updateState("segmentosSelecionados")}
            />
            <Select
                className={classes.selectMenuItem}
                value={filtrosSelecionados.segmentosMarketingSelecionados}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.segmentosMarketingDisponveis)}
                variant="mobile"
                label={labels.filtro.segmentoMarketing}
                isMulti
                fullWidth
                onChange={updateState("segmentosMarketingSelecionados")}
            />
            <Select
                className={classes.selectMenuItem}
                value={filtrosSelecionados.agrupamentoMarcasSelecionados}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.agrupamentoMarcasDisponiveis)}
                variant="mobile"
                label="Agrupamentos Marcas"
                isMulti
                fullWidth
                onChange={updateState("agrupamentoMarcasSelecionados")}
            />
            <Select
                className={classes.selectMenuItem}
                value={filtrosSelecionados.marcasSelecionadas}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.marcasDisponiveis)}
                variant="mobile"
                label="Marcas"
                isMulti
                fullWidth
                onChange={updateState("marcasSelecionadas")}
            />
        </Box>
    );
};

export default FiltroSegmentacao;
