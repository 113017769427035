export class OpcaoFiltro {
    constructor(public label: string, public value: string, public padrao: boolean, public item?: any) {}
}

export const getOpcaoFiltroAsSelectOptions = (items: OpcaoFiltro[]) => {
    if (!items || items.length === 0) {
        return [];
    }
    return items.map(opcao => ({
        value: opcao.value,
        label: opcao.label,
        disabled: false
    }));
};
