import { Box, Typography } from "@material-ui/core";
import { Select } from "@tecsinapse/ui-kit";
import { State } from "api/redux/model";
import React from "react";
import { useSelector } from "react-redux";
import { useFiltroStyles } from "ui/components/Filtro/css/FiltroStyle";
import { FiltrosSelecionados, getOpcaoFiltroAsSelectOptions } from "./model";

interface FiltroRegiaoProps {
    filtrosSelecionados: FiltrosSelecionados;
    updateState: Function;
}

const FiltroRegiao: React.FC<FiltroRegiaoProps> = ({ filtrosSelecionados, updateState }) => {
    const classes = useFiltroStyles();
    const labels = useSelector((state: State) => state.labels);
    return (
        <Box padding={1}>
            <Typography className={classes.subItemHeader}>Região</Typography>
            <Select
                className={classes.selectMenuItem}
                label="Divisões Regionais"
                value={filtrosSelecionados.divisoesRegionaisSelecionadas}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.divisoesRegionaisDisponiveis)}
                variant="mobile"
                isMulti
                fullWidth
                onChange={updateState("divisoesRegionaisSelecionadas")}
            />
            <Select
                className={classes.selectMenuItem}
                label="Estados"
                value={filtrosSelecionados.estadosSelecionados}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.estadosDisponiveis)}
                onChange={updateState("estadosSelecionados")}
                variant="mobile"
                isMulti
                fullWidth
            />
            <Select
                className={classes.selectMenuItem}
                label={labels.filtro.areaAtuacao}
                value={filtrosSelecionados.areasAtuacoesSelecionadas}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.areasAtuacoesDisponiveis)}
                onChange={updateState("areasAtuacoesSelecionadas")}
                variant="mobile"
                isMulti
                fullWidth
            />
        </Box>
    );
};

export default FiltroRegiao;
