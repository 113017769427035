import { TipoAgrupamentoMarketShare } from "api/hooks/useMarketShare/model/TipoAgrupamentoMarketShare";
import { AgrupamentoMarcas } from "../../../model/AgrupamentoMarcas";
import { DadosMarketShareItem } from "./DadosMarketShareItem";

export class DadosMarketShare {

    totalizador : DadosMarketShareItem = new DadosMarketShareItem();
	itensMarketShare : Map<string, DadosMarketShareItem> = new Map();
	usarAgrupamentoMarcas: boolean;
	quantidadeDiasUteisPeriodo: number = 1;
	quantidadeDiasUteisMes: number = 1;

    constructor(private atributoChave : TipoAgrupamentoMarketShare, private agrupamentoMarcas: Array<AgrupamentoMarcas>) {
        this.usarAgrupamentoMarcas = this.atributoChave === "marca_nome" && this.agrupamentoMarcas && this.agrupamentoMarcas.length > 0;
	}

    private computaDados(dados, funcao) {
        dados.forEach(dado => {
            let chave : string = this.getChave(dado);
            let item = this.itensMarketShare.get(chave);

            if (!item) {
                item = new DadosMarketShareItem();
                item.corMarca = this.getCor(dado);
                this.itensMarketShare.set(chave, item);
            }
            funcao(item, dado["quantidade"]);
        })
	}
	
	private getChave(dado): string {
		if (this.usarAgrupamentoMarcas) {
			let agrupamento = this.agrupamentoMarcas.filter(agrupamento => agrupamento.marcas.some(marca => marca.nome === dado[this.atributoChave]))[0];
			return agrupamento ? agrupamento.nome : dado[this.atributoChave];
		}
		return dado[this.atributoChave];
    }
	
	private getCor(dado): string {
		if (this.usarAgrupamentoMarcas) {
			let agrupamento = this.agrupamentoMarcas.filter(agrupamento => agrupamento.marcas.some(marca => marca.nome === dado[this.atributoChave]))[0];
			return agrupamento ? agrupamento.cor : dado['marca_cor'];
		}
		return dado['marca_cor'];
	}

    private getTotalItens(itens : DadosMarketShareItem[], funcaoValor) : number{
        let total : number = 0;
        itens.forEach(item => {
            total = total + this.getValorOrZero(funcaoValor(item));
        });
        return total;
    }

    private getTotal(funcaoValor) : number{
        return this.getTotalItens(Array.from(this.itensMarketShare.values()), funcaoValor);
    }

    public addVolumeDia(dadosDia : Object[]){
        this.computaDados(dadosDia, (item : DadosMarketShareItem, quantidade) => item.volumeDia = this.getValorOrZero(item.volumeDia) + this.getValorOrZero(quantidade));
        this.totalizador.volumeDia = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeDia);
    }

    public addVolumeDiaPd(dadosDiaPd : Object[]){
        this.computaDados(dadosDiaPd, (item : DadosMarketShareItem, quantidade) => item.volumeDiaPd = this.getValorOrZero(item.volumeDiaPd) + this.getValorOrZero(quantidade));
		this.totalizador.volumeDiaPd = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeDiaPd);
    }

    public addVolumeMtd(dadosMtd : Object[]){
        this.computaDados(dadosMtd, (item : DadosMarketShareItem, quantidade) => item.volumeMtd = this.getValorOrZero(item.volumeMtd) + this.getValorOrZero(quantidade));
		this.totalizador.volumeMtd = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeMtd);
    }

    public addVolumeMtdPm(dadosMtdPm : Object[]){
        this.computaDados(dadosMtdPm, (item : DadosMarketShareItem, quantidade) => item.volumeMtdPm = this.getValorOrZero(item.volumeMtdPm) + this.getValorOrZero(quantidade));
		this.totalizador.volumeMtdPm = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeMtdPm);
    }

    public addVolumeMtdPy(dadosMtdPy : Object[]){
        this.computaDados(dadosMtdPy, (item : DadosMarketShareItem, quantidade) => item.volumeMtdPy = this.getValorOrZero(item.volumeMtdPy) + this.getValorOrZero(quantidade));
		this.totalizador.volumeMtdPy = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeMtdPy);
    }

    public addVolumeYtd(dadosYtd : Object[]){
        this.computaDados(dadosYtd, (item : DadosMarketShareItem, quantidade) => item.volumeYtd = this.getValorOrZero(item.volumeYtd) + this.getValorOrZero(quantidade));
		this.totalizador.volumeYtd = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeYtd);
    }

    public addVolumeYtdPm(dadosYtdPm : Object[]){
        this.computaDados(dadosYtdPm, (item : DadosMarketShareItem, quantidade) => item.volumeYtdPm = this.getValorOrZero(item.volumeYtdPm) + this.getValorOrZero(quantidade));
        this.totalizador.volumeYtdPm = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeYtdPm);
    }

    public addVolumeYtdPy(dadosYtdPy : Object[]){
        this.computaDados(dadosYtdPy, (item : DadosMarketShareItem, quantidade) => item.volumeYtdPy = this.getValorOrZero(item.volumeYtdPy) + this.getValorOrZero(quantidade));
        this.totalizador.volumeYtdPy = this.getTotal((marketShare : DadosMarketShareItem) => marketShare.volumeYtdPy);
    }

    public clonaMantendoTotalizadorETotalizandoItensComoItemUnico(chave : string, itens : DadosMarketShareItem[]){
        let marketShare : DadosMarketShare = new DadosMarketShare(this.atributoChave, this.agrupamentoMarcas);
		marketShare.totalizador = this.totalizador;
		marketShare.quantidadeDiasUteisMes = this.quantidadeDiasUteisMes;
		marketShare.quantidadeDiasUteisPeriodo = this.quantidadeDiasUteisPeriodo;
        
        let marketShareItemTotalizado : DadosMarketShareItem = new DadosMarketShareItem();
        marketShareItemTotalizado.volumeDia = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeDia);
        marketShareItemTotalizado.volumeDiaPd = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeDiaPd);
        marketShareItemTotalizado.volumeMtd = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeMtd);
        marketShareItemTotalizado.volumeMtdPm = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeMtdPm);
        marketShareItemTotalizado.volumeMtdPy = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeMtdPy);
        marketShareItemTotalizado.volumeYtd = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeYtd);
        marketShareItemTotalizado.volumeYtdPm = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeYtdPm);
        marketShareItemTotalizado.volumeYtdPy = this.getTotalItens(itens, (marketShareItem : DadosMarketShareItem) => marketShareItem.volumeYtdPy);
        
        marketShare.itensMarketShare.clear();
        marketShare.itensMarketShare.set(chave, marketShareItemTotalizado);
        return marketShare;
    }

    private calculaPercentual(dividendo : number, divisor : number){
        if(divisor === 0){
            return 0;
        }
        return (dividendo / divisor) * 100;
    }

    public volumeDia(chave : string) : number{
        return this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeDia);
    }

    public percentualDia(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeDia), this.getValorOrZero(this.totalizador.volumeDia));
    }

    private percentualDiaPd(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeDiaPd), this.getValorOrZero(this.totalizador.volumeDiaPd));
    }

    public evolucaoDia(chave : string) : number{
        return this.percentualDia(chave) - this.percentualDiaPd(chave);
    }

    public volumeMtd(chave : string) : number{
        return this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeMtd);
    }

    public percentualMtd(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeMtd), this.getValorOrZero(this.totalizador.volumeMtd));
    }

    public percentualMtdPm(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeMtdPm), this.getValorOrZero(this.totalizador.volumeMtdPm));
    }

    public percentualMtdPy(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeMtdPy), this.getValorOrZero(this.totalizador.volumeMtdPy));
    }

    public evolucaoMtd(chave : string) : number{
        return this.percentualMtd(chave) - this.percentualMtdPm(chave);
    }

    public evolucaoMtdVsPy(chave : string) : number{
        return this.percentualMtd(chave) - this.percentualMtdPy(chave);
    }

    public volumeYtd(chave : string) : number{
        return this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeYtd);
    }

    public percentualYtd(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeYtd), this.getValorOrZero(this.totalizador.volumeYtd));
    }

    public percentualYtdPy(chave : string) : number{
        return this.calculaPercentual(this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeYtdPy), this.getValorOrZero(this.totalizador.volumeYtdPy));
    }

    public evolucaoYtd(chave : string) : number{
        return this.percentualYtd(chave) - this.percentualYtdPy(chave);
    }

    public volumeYtdPm(chave : string) : number{
        return this.getValorOrZero(this.itensMarketShare.get(chave)?.volumeYtdPm);
    }

    public chavesOrdendasPorYtdDecrescente() : string[] {
        return this.chavesOrdendasPor('volumeYtd');
    }

    public chavesOrdendasPorMtdDecrescente() : string[] {
        return this.chavesOrdendasPor('volumeMtd');
    }

    public chavesOrdendasPorDayDecrescente() : string[] {
        return this.chavesOrdendasPor('volumeDia');
    }

    public itemMarketShare(chave: string): DadosMarketShareItem {
        return this.itensMarketShare.get(chave) || {};
    }

    public chavesOrdendasPor(atributoOrdenacao, desc = true) : string[] {
        return Array.from(this.itensMarketShare.entries())
            .sort((a, b) => { 
                if (desc) {
                    return this.getValorOrZero(b[1][atributoOrdenacao]) - this.getValorOrZero(a[1][atributoOrdenacao]);
                }
                return this.getValorOrZero(a[1][atributoOrdenacao]) - this.getValorOrZero(b[1][atributoOrdenacao]);
            })
            .map(i => i[0]);
    }

    private getValorOrZero(valor? : number) : number {
        if(!valor) {
            return 0;
        }
        return valor;
    }

	public mediaDiaria(chave : string) : number{
		return this.volumeMtd(chave) / this.quantidadeDiasUteisPeriodo;
    }

	public projecaoMensal(chave : string) : number{
        return this.mediaDiaria(chave) * this.quantidadeDiasUteisMes;
    }

    public projecaoAnual(chave : string) : number {
        return this.volumeYtdPm(chave) + this.projecaoMensal(chave);
    }

    public clone() {
        const novoDadosMarketShare = new DadosMarketShare(this.atributoChave, []);
        novoDadosMarketShare.totalizador = this.totalizador;
        novoDadosMarketShare.itensMarketShare = this.itensMarketShare;
        novoDadosMarketShare.usarAgrupamentoMarcas = this.usarAgrupamentoMarcas;
        novoDadosMarketShare.quantidadeDiasUteisPeriodo = this.quantidadeDiasUteisPeriodo;
        novoDadosMarketShare.quantidadeDiasUteisMes = this.quantidadeDiasUteisMes;
        return novoDadosMarketShare;
    }
}