import { DadosMarketShare, DadosMarketShareItem } from "api";
import { DateTime } from "luxon";
import { TipoFormatoDadosRelatorio } from "ui/components/FormatoDadosRelatorio";

export const criaColunasPorTipoVisao = (dataReferencia: string) => {
    const colunasPorTipoVisao = new Map<TipoFormatoDadosRelatorio, Object[]>();
    colunasPorTipoVisao.set(TipoFormatoDadosRelatorio.ABSOLUTO, getColunasTipoVisaoAbsolutoPercentual(dataReferencia));
    colunasPorTipoVisao.set(TipoFormatoDadosRelatorio.EVOLUCAO, getColunasTipoVisaoEvolutivo(dataReferencia));
    colunasPorTipoVisao.set(
        TipoFormatoDadosRelatorio.PERCENTUAL,
        getColunasTipoVisaoAbsolutoPercentual(dataReferencia)
    );
    return colunasPorTipoVisao;
};

const getColunasTipoVisaoAbsolutoPercentual = (dataReferencia: string) => {
    const data = DateTime.fromFormat(dataReferencia, "yyyy-MM-dd");
    const dia = data.toFormat("dd");
    const mesAno = data.toFormat("MMM/yy");
    const ano = data.toFormat("yy");

    return [
        { field: "descricao", title: "", align: "left" },
        { field: "day", title: `DAY`, value: dia, align: "right" },
        { field: "mtd", title: `MTD`, value: mesAno, align: "right" },
        { field: "ytd", title: `YTD`, value: ano, align: "right" }
    ];
};

const getColunasTipoVisaoEvolutivo = (dataReferencia: string) => {
    const data = DateTime.fromFormat(dataReferencia, "yyyy-MM-dd");

    let DAYS_TO_SUBTRACT = 1;
    if (data.weekday === 1) {
        DAYS_TO_SUBTRACT = 3;
    }

    let dataAnterior = data.minus({ day: DAYS_TO_SUBTRACT });

    let diaAtual: string | null = data.toFormat("dd");
    let diaAnterior: string | null = dataAnterior.toFormat("dd");

    if (data.hasSame(DateTime.local(), "day")) {
        diaAtual = data.toRelativeCalendar();
        if (DAYS_TO_SUBTRACT === 1) {
            diaAnterior = dataAnterior.toRelativeCalendar();
        }
    }

    let mesAnoAtual = data.toFormat("MMM");
    let anoAtual = data.toFormat("yy");

    let mesAnoAnterior = data.minus({ month: 1 }).toFormat("MMM");
    let anoAnterior = data.minus({ year: 1 }).toFormat("yy");

    return [
        { field: "descricao", title: "", align: "left" },
        { field: "day", title: `DAY`, value: `${diaAnterior} x ${diaAtual}`, align: "right" },
        { field: "mtd", title: `MTD`, value: `${mesAnoAnterior} x ${mesAnoAtual}`, align: "right" },
        { field: "ytd", title: `YTD`, value: `${anoAnterior} x ${anoAtual}`, align: "right" }
    ];
};

export const converteDadosParaLinhasTabela = (
    formatoDadosRelatorio: TipoFormatoDadosRelatorio,
    quantidadeRegistros: number,
    dadosMarketShare?: DadosMarketShare
) => {
    if (!dadosMarketShare) {
        return [];
    }

    let dadosTabela: any[] = [];
    let chavesOrdenados: string[] = dadosMarketShare.chavesOrdendasPorMtdDecrescente();

    if (
        formatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO ||
        formatoDadosRelatorio === TipoFormatoDadosRelatorio.EVOLUCAO
    ) {
        let marketShareTotal = dadosMarketShare.clonaMantendoTotalizadorETotalizandoItensComoItemUnico(
            "Total",
            Array.from(dadosMarketShare.itensMarketShare.values())
        );
        dadosTabela.push(criaLinhaTabela("Total", marketShareTotal, formatoDadosRelatorio));
    }

    let chavesExibicao: string[] = chavesOrdenados.slice(0, quantidadeRegistros);
    chavesExibicao.forEach(chaveExibicao =>
        dadosTabela.push(criaLinhaTabela(chaveExibicao, dadosMarketShare, formatoDadosRelatorio))
    );

    let chavesOthers: string[] = chavesOrdenados.slice(quantidadeRegistros);
    if (chavesOthers && chavesOthers.length > 0) {
        let itensOthers: DadosMarketShareItem[] = Array.from(dadosMarketShare.itensMarketShare.entries())
            .filter(item => chavesOthers.indexOf(item[0]) >= 0)
            .map(i => i[1]);
        let marketShareOthers = dadosMarketShare.clonaMantendoTotalizadorETotalizandoItensComoItemUnico(
            "Others",
            itensOthers
        );
        dadosTabela.push(criaLinhaTabela("Others", marketShareOthers, formatoDadosRelatorio));
    }

    return dadosTabela;
};

const criaLinhaTabela = (
    chave: string,
    dadosMarketShare: DadosMarketShare,
    formatoDadosRelatorio: TipoFormatoDadosRelatorio
) => {
    let day;
    let mtd;
    let ytd;

    if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
        day = dadosMarketShare.volumeDia(chave);
        mtd = dadosMarketShare.volumeMtd(chave);
        ytd = dadosMarketShare.volumeYtd(chave);
    } else if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.EVOLUCAO) {
        day = dadosMarketShare.evolucaoDia(chave);
        mtd = dadosMarketShare.evolucaoMtd(chave);
        ytd = dadosMarketShare.evolucaoYtd(chave);
    } else if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.PERCENTUAL) {
        day = dadosMarketShare.percentualDia(chave);
        mtd = dadosMarketShare.percentualMtd(chave);
        ytd = dadosMarketShare.percentualYtd(chave);
    }

    return { id: chave, descricao: `${chave}`, day, mtd, ytd, formatoDadosRelatorio };
};
