import { makeStyles } from "@material-ui/core";

export const useClippingStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    botaoAbrir: {
        width: 28,
        height: 28,
        padding: 2
    }
}));
