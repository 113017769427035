import { useLazyQuery } from "@apollo/react-hooks";
import { AgrupamentoRenavamQuery, criaConsultaAgrupamentoRenavam, FiltrosSelecionados } from "api";
import { State } from "api/redux/model";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";
import { aplicarFiltro, atualizaDadosGrafico, customizaFiltro, getDescricaoRelatorio } from "./util/funcoesMothly";
import { OpcoesGraficoMonthly } from "./util/OpcoesGraficoMonthly";

const agrupamento = new AgrupamentoRenavamQuery();
agrupamento.comAnoMes();
agrupamento.comQuantidade();

const Monthly: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const appState = useSelector((state: State) => state.app);
    const descricaoRelatorio = getDescricaoRelatorio(appState.usuarioLogado);
    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltrosSelecionados>();
    const [opcoesGrafico, setOpcoesGrafico] = useState<Highcharts.Options>(OpcoesGraficoMonthly);
    const [carregaDadosRelatorio, { loading, data }] = useLazyQuery(
        criaConsultaAgrupamentoRenavam("monthly", agrupamento)
    );

    useEffect(() => {
        if (data && data.agrupamentoRenavam) {
            atualizaDadosGrafico(setOpcoesGrafico, data);
        }
    }, [data]);

    useEffect(() => {
        if (filtro) {
            aplicarFiltro(carregaDadosRelatorio, setFiltrosSelecionados)(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ViewLayout
            carregando={loading}
            titulo="Monthly"
            descricao={descricaoRelatorio}
            filtroProps={{
                customizaFiltro,
                aplicarFiltro: aplicarFiltro(carregaDadosRelatorio, setFiltrosSelecionados)
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.MONTHLY,
                filtrosSelecionados
            }}
            {...viewLayoutProps}>
            <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} />
        </ViewLayout>
    );
};

export default Monthly;
