import gql from "graphql-tag";

export function salvarConfiguracaoClippingMutation() {
    return gql`
        mutation salvarUsuarioClipping($configuracaoClipping: UsuarioClippingInput!) {
            salvarUsuarioClipping(clipping: $configuracaoClipping)
        }
    `;
}

export function editarConfiguracaoClippingMutation() {
    return gql`
        mutation editarClippingItem($id: ID!, $descricao: String!) {
            editarClippingItem(id: $id, descricao: $descricao)
        }
    `;
}
