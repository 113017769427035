export class Regiao {
    constructor(readonly nome:String, readonly estados:Array<String>) {}
}

export class Regioes {
    static SUL = new Regiao("SUL", ["RS", "SC", "PR"]);
    static SUDESTE = new Regiao("SUDESTE", ["ES","MG","RJ","SP"]);
    static CENTRO_OESTE = new Regiao("CENTRO_OESTE", ["DF","GO","MS","MT"]);
    static NORDESTE = new Regiao("NORDESTE", ["AL","BA","CE","MA","PB","PE","PI","RN","SE"]);
    static NORTE = new Regiao("NORTE", ["AC","AM","AP","PA","RO","RR","TO"]);
    static regioes = [Regioes.SUL, Regioes.SUDESTE, Regioes.CENTRO_OESTE, Regioes.NORDESTE, Regioes.NORTE];
}
