import * as Highcharts from "highcharts";

export const grafico: Highcharts.Options = {
    chart: {
        plotShadow: false,
        type: "pie"
    },
    title: {
        text: ""
    },
    tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage: .1f} %</b>"
    },
    exporting: { enabled: false },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.y}"
            }
        }
    },
    series: [],
    credits: {
        enabled: false
    },
    lang: {
        noData: "Não há dados para exibição",
        loading: "Carregando informações..."
    }
};
