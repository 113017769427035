import { FiltrosSelecionados } from "api";
import { TipoUsuario } from "api/enums/TipoUsuario";
import { Usuario } from "api/model/Usuario";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";

export const getDescricaoRelatorio = (usuarioLogado: Usuario) => {
    if (usuarioLogado.tipoUsuario === TipoUsuario.DEALER_GRUPO) {
        return "Visão mensal com os emplacamentos da indústria. Como padrão, são mostrados os 6 últimos meses e apenas a área de atuação do dealer.";
    }
    return "Visão mensal com os emplacamentos da indústria. Como padrão, são mostrados os 6 últimos meses.";
};

export const customizaFiltro = (filtro: FiltrosSelecionados) => {
    filtro.dataInicio = DateTime.fromFormat(filtro.dataInicio, "yyyy-MM-dd")
        .minus({ month: 5 })
        .toFormat("yyyy-MM-dd");
    filtro.selecionarTodosTiposModelosConceitosSegmentosEMarcas();
};

export const aplicarFiltro = (carregaDadosRelatorio, setFiltrosSelecionados) => (filtro: FiltrosSelecionados) => {
    carregaDadosRelatorio({
        variables: {
            filtro: filtro.selecionadosAsString()
        }
    });
    setFiltrosSelecionados(filtro);
};

export const atualizaDadosGrafico = (setOpcoesGrafico, dadosRelatorio) => {
    setOpcoesGrafico((estadoAnterior: Highcharts.Options) => {
        const categories: any[] = [];
        const data: any[] = [];

        dadosRelatorio.agrupamentoRenavam
            .slice()
            .sort((r1, r2) => r1.ano_mes - r2.ano_mes)
            .forEach(quantidadeAnoMes => {
                categories.push(DateTime.fromFormat(quantidadeAnoMes["ano_mes"], "yyyyMM").toFormat("MMM/yy"));
                data.push(quantidadeAnoMes["quantidade"]);
            });

        const novoEstado = cloneDeep(estadoAnterior);
        novoEstado.xAxis = {
            ...estadoAnterior.xAxis,
            categories
        };
        novoEstado.series = [
            {
                name: " ",
                type: "column",
                data,
                showInLegend: false
            }
        ];

        return novoEstado;
    });
};
