import { useMutation } from "@apollo/react-hooks";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { TipoAcaoApp } from "api";
import { ApolloQueryResult, OperationVariables } from "apollo-client";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import BoxCard from "ui/components/BoxCard/BoxCard";
import { Notificacao } from "ui/views/Notificacoes/model/Notificacao";
import { marcarNotificacaoComoLida } from "ui/views/Notificacoes/mutations";

const useStyles = makeStyles(() => ({
    rodape: {
        color: "#969696"
    }
}));

const acaoNotificacao = (
    notificacao: Notificacao,
    history,
    refetchNotificacoes,
    dispatch,
    mutationMarcarNotificacaoComoLida
) => {
    return () => {
        mutationMarcarNotificacaoComoLida({ variables: { id: notificacao.id } })
            .then(result => result.data && result.data["marcarNotificacaoComoLida"])
            .then(marcouComoLida => {
                if (marcouComoLida) {
                    dispatch({
                        type: TipoAcaoApp.DECREMENTA_NOTIFICACAO_NAO_LIDA,
                        data: {}
                    });
                    if (!notificacao.url) {
                        refetchNotificacoes();
                    }
                }
                if (notificacao.url) {
                    history.push(notificacao.url);
                }
            });
    };
};

interface NotificacaoItemProps {
    notificacao: Notificacao;
    refetchNotificacoes(variables?: OperationVariables): Promise<ApolloQueryResult<any>>;
}

const NotificacaoItem: React.FC<NotificacaoItemProps> = ({ notificacao, refetchNotificacoes }) => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mutationMarcarNotificacaoComoLida] = useMutation(marcarNotificacaoComoLida, {
        variables: { id: notificacao.id }
    });

    return (
        <BoxCard
            display="flex"
            flexDirection="column"
            padding={0.5}
            onClick={acaoNotificacao(
                notificacao,
                history,
                refetchNotificacoes,
                dispatch,
                mutationMarcarNotificacaoComoLida
            )}>
            <Box style={{ opacity: notificacao.lida ? 0.7 : 1 }}>
                <Box fontSize={12} fontWeight="bold" color="secondary.main" marginBottom={0.4}>
                    {notificacao.tipoNotificacao}
                </Box>
                <Box marginBottom={0.4} fontWeight="fontWeightMedium" fontSize={13}>
                    {notificacao.mensagem}
                </Box>
                <Box>
                    <Typography variant="caption" className={classes.rodape}>
                        {notificacao.dataHoraEnvio}
                    </Typography>
                </Box>
            </Box>
        </BoxCard>
    );
};

export default NotificacaoItem;
