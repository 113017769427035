import { ReduxAction, TipoAcaoApp, OpcaoFiltro } from "api/model";
import { FiltroState } from "../model/FiltroState";
import { Estados } from "api/enums";

export const INITIAL_STATE: FiltroState = {
    marcaDaMontadora: { codigo: "", nome: "" },
    tiposModelosDisponiveis: [],
    conceitosDisponiveis: [],
    segmentosDisponiveis: [],
    segmentosMarketingDisponiveis: [],
    agrupamentosMarcasDisponiveis: [],
    marcasDisponiveis: [],
    divisoesRegionaisDisponiveis: [],
    estadosDisponiveis: Estados.getEstados().map(estado => new OpcaoFiltro(estado.descricao, estado.sigla, true)),
    areasAtuacaoDisponiveis: []
};

const filtroReducer = (state: FiltroState = INITIAL_STATE, action: ReduxAction) => {
    if (action.type === TipoAcaoApp.SET_DADOS_INICIAIS_FILTRO) {
        const marcaDaMontadora = action.data.marcaDaMontadora;
        const tiposModelosDisponiveis = action.data.tiposModelosDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.codigo, item.padrao)
        );
        const conceitosDisponiveis = action.data.conceitosDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.codigo, item.padrao)
        );
        const segmentosDisponiveis = action.data.segmentosDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.codigo, item.padrao)
        );
        const segmentosMarketingDisponiveis = action.data.segmentosMarketingDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.codigo, item.padrao)
        );
        const agrupamentosMarcasDisponiveis = action.data.agrupamentosMarcasDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.id, item.padrao, item)
        );
        const marcasDisponiveis = action.data.marcasDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.codigo, item.padrao)
        );
        const divisoesRegionaisDisponiveis = action.data.divisoesRegionaisDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.pk, item.padrao)
        );
        const areasAtuacaoDisponiveis = action.data.areasAtuacaoDisponiveis.map(
            item => new OpcaoFiltro(item.nome, item.pk, item.padrao)
        );

        return {
            ...state,
            marcaDaMontadora,
            tiposModelosDisponiveis,
            conceitosDisponiveis,
            segmentosDisponiveis,
            segmentosMarketingDisponiveis,
            agrupamentosMarcasDisponiveis,
            marcasDisponiveis,
            divisoesRegionaisDisponiveis,
            areasAtuacaoDisponiveis
        };
    }
    return state;
};

export default filtroReducer;
