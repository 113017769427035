import { Box, Typography } from "@material-ui/core";
import { DatePicker } from "@tecsinapse/pickers";
import { DateTime } from "luxon";
import React from "react";
import { useFiltroStyles } from "ui/components/Filtro/css/FiltroStyle";
import { FiltrosSelecionados } from "./model";

interface FiltroPeriodoProps {
    periodo: boolean;
    filtrosSelecionados: FiltrosSelecionados;
    updateState: Function;
}

const FiltroPeriodo: React.FC<FiltroPeriodoProps> = ({ periodo = false, filtrosSelecionados, updateState }) => {
    const classes = useFiltroStyles();
    let dataInicialComponent;

    const dataInicial = DateTime.fromFormat(filtrosSelecionados.dataInicio, "yyyy-MM-dd");
    const dataFinal = DateTime.fromFormat(filtrosSelecionados.dataFim, "yyyy-MM-dd");

    if (periodo) {
        dataInicialComponent = (
            <DatePicker
                fullWidth
                format="dd/MM/yyyy"
                label="Data Inicial"
                name="dataInicial"
                selectedDate={dataInicial}
                onChange={data => updateState("dataInicio")(data.toFormat("yyyy-MM-dd"))}
            />
        );
    }

    return (
        <Box padding={1}>
            <Typography className={classes.subItemHeader}>Período</Typography>
            {dataInicialComponent}
            <DatePicker
                className={classes.selectMenuItem}
                format="dd/MM/yyyy"
                fullWidth
                label={periodo ? "Data Final" : "Data"}
                selectedDate={dataFinal}
                name="dataFinal"
                onChange={data => updateState("dataFim")(data.toFormat("yyyy-MM-dd"))}
            />
        </Box>
    );
};

export default FiltroPeriodo;
