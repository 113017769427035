export enum TipoAcaoApp {
    SET_URL_SERVER,
    SELECT_BRAND,
    LAST_DATA_UPDATE,
    SET_LOGGED_USER,
    SET_DADOS_INICIAIS_FILTRO,
    SET_TIPOS_NOTIFICOES_USUARIO,
    SET_TIPO_NOTIFICAO_USUARIO,
    REMOVE_TIPO_NOTIFICAO_USUARIO,
    SET_APP_PROPERTIES,
    SET_NOTIFICACOES_ATIVAS,
    SET_LABELS,
    SET_NOTIFICACOES_NAO_LIDAS,
    DECREMENTA_NOTIFICACAO_NAO_LIDA
}

export interface ReduxAction {
    type: TipoAcaoApp;
    data: any;
}
