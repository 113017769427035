import gql from "graphql-tag";

export function usuarioPorEmail(email: string) {
    let query = gql`
        query usuarioPorEmail($email: String!) {
            usuarioPorEmail(email: $email) {
                nome
                email
                tipoUsuario
                funcionalidades
                areasAtuacao {
                    pk
                    nome
                    montadora
                }
                marcas
            }
        }
    `;

    return {
        query,
        variables: {
            email
        }
    };
}
