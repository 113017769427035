import { Box, Typography } from "@material-ui/core";
import { Select } from "@tecsinapse/ui-kit";
import React from "react";
import { useFiltroStyles } from "ui/components/Filtro/css/FiltroStyle";
import { FiltrosSelecionados, getOpcaoFiltroAsSelectOptions } from "./model";

interface FiltroVisaoProps {
    filtrosSelecionados: FiltrosSelecionados;
    updateState: Function;
}

const FiltroVisao: React.FC<FiltroVisaoProps> = ({ filtrosSelecionados, updateState }) => {
    const classes = useFiltroStyles();
    return (
        <Box padding={1}>
            <Typography className={classes.subItemHeader}>Visão</Typography>
            <Select
                className={classes.selectMenuItem}
                label="Tipo de Venda"
                value={filtrosSelecionados.tipoFiltroVendaDiretaSelecionado}
                options={getOpcaoFiltroAsSelectOptions(filtrosSelecionados.tipoFiltroVendaDiretaDisponveis || [])}
                variant="mobile"
                onChange={updateState("tipoFiltroVendaDiretaSelecionado")}
                fullWidth
            />
        </Box>
    );
};

export default FiltroVisao;
