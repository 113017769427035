import { ReduxAction, TipoAcaoApp } from "api/model";
import { LabelsState } from "api/redux/model/LabelsState";
import { cloneDeep } from "lodash";

export const INITIAL_STATE: LabelsState = {
    filtro: {}
};

const labelReducer = (state: LabelsState = INITIAL_STATE, action: ReduxAction) => {
    if (action.type === TipoAcaoApp.SET_LABELS) {
        const novoEstado = cloneDeep(state);
        novoEstado.filtro = action.data;
        return action.data;
    }
    return state;
};

export default labelReducer;
