import gql from "graphql-tag";

export const notificacoesUsuarioQuery = gql`
    query buscaNotificacoesUsuario($montadora: Montadora!, $email: String!, $offset: Int!, $limit: Int!) {
        buscaNotificacoesUsuario(montadora: $montadora, email: $email, offset: $offset, limit: $limit) {
            id
            tipoNotificacao
            dataHoraEnvio
            lida
            mensagem
            url
        }
        totalNotificacoesUsuario(montadora: $montadora, email: $email)
    }
`;
