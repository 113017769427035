import Clipping from "ui/views/Clipping/Clipping";
import Configuracoes from "ui/views/Configuracoes/Configuracoes";
import Home from "ui/views/Home/Home";
import MarketShare from "ui/views/MarketShare/MarketShare";
import Montadora from "ui/views/Montadora/Montadora";
import Monthly from "ui/views/Monthly/Monthly";
import Notificacoes from "ui/views/Notificacoes/Notificacoes";
import RegionalAnalysis from "ui/views/RegionalAnalysis/RegionalAnalysis";
import Segmento from "ui/views/Segmento/Segmento";
import SubSegmento from "ui/views/SubSegmento/SubSegmento";
import TotalIndustry from "ui/views/TotalIndustry/TotalIndustry";
import UflAnalysis from "ui/views/UfAnalysis/UfAnalysis";

export const Routes = {
    ROOT: { route: "/", label: "Home", component: Home },
    MARKET_SHARE: {
        route: "/market-share",
        label: "Market Share",
        component: MarketShare,
        menu: true
    },
    REGIONAL_ANALYSIS: {
        route: "/regional_analysis",
        label: "Regional Analysis",
        component: RegionalAnalysis,
        menu: true
    },
    UF_ANALYSIS: {
        route: "/uf_analysis",
        label: "Uf Analysis",
        component: UflAnalysis,
        menu: true
    },
    MONTHLY: {
        route: "/monthly",
        label: "Monthly",
        component: Monthly,
        menu: true
    },
    MONTADORA: {
        route: "/montadora",
        label: "Montadora",
        component: Montadora,
        menu: true
    },
    TOTAL_INDUSTRY: {
        route: "/total-industry",
        label: "Total Industry",
        component: TotalIndustry,
        menu: true
    },
    SUBSEGMENTO: {
        route: "/sub-segmento",
        label: "SubSegmento",
        component: SubSegmento,
        menu: true
    },
    SEGMENTO: {
        route: "/segmento",
        label: "Segmento",
        component: Segmento,
        menu: true
    },
    CLIPPING: {
        route: "/clipping",
        label: "Clipping",
        component: Clipping,
        menu: true
    },
    CLIPPING_PARAM: {
        route: "/clipping/:montadora/:dataReferencia",
        label: "Clipping",
        component: Clipping,
        menu: false
    },
    CLIPPING_PARAM_NOTIFICACAO: {
        route: "/clipping/:montadora/:dataReferencia/:idNotificacao",
        label: "Clipping",
        component: Clipping,
        menu: false
    },
    CONFIGURATIONS: {
        route: "/configuracoes",
        label: "Configurações",
        component: Configuracoes,
        menu: true
    },
    NOTIFICACOES: {
        route: "/notificacoes",
        label: "Notificações",
        component: Notificacoes,
        menu: true
    }
};

export const publicRoutes = [
    // "/",
    "/esqueci-senha",
    "/forgot-password",
    "/olvide-contrasena"
];
