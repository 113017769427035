import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { mdiChartLine, mdiDotsVertical, mdiFinance, mdiNumeric1Box, mdiPercent } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactNode, useState } from "react";
import { TipoFormatoDadosRelatorio } from "./model/TipoFormatoDadosRelatorio";

export const useStyles = makeStyles(() => ({
    eyeButton: {
        width: 28,
        height: 28,
        padding: 2
    }
}));

export interface FormatoDadosRelatorioProps {
    percentual?: boolean;
    absoluto?: boolean;
    evolucao?: boolean;
    mediaProjecao?: boolean;
    onSelect?: Function;
}

const onClickItem = (
    tipoFormatoDadosRelatorio: TipoFormatoDadosRelatorio,
    setOpen: Function,
    onSelect?: Function
) => () => {
    if (onSelect) {
        onSelect(tipoFormatoDadosRelatorio);
    }
    setOpen(false);
};

const FormatoDadosRelatorio: React.FC<FormatoDadosRelatorioProps> = ({
    absoluto = true,
    evolucao = true,
    mediaProjecao = true,
    percentual = true,
    onSelect
}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const tiposVisoesAsComponents: ReactNode[] = [];

    if (absoluto) {
        tiposVisoesAsComponents.push(
            <ListItem
                button
                key="absoluto"
                onClick={onClickItem(TipoFormatoDadosRelatorio.ABSOLUTO, setOpen, onSelect)}>
                <ListItemIcon>
                    <Icon path={mdiNumeric1Box} size={1} />
                </ListItemIcon>
                <ListItemText primary="Absoluto" />
            </ListItem>
        );
    }
    if (percentual) {
        tiposVisoesAsComponents.push(
            <ListItem
                button
                key="percentual"
                onClick={onClickItem(TipoFormatoDadosRelatorio.PERCENTUAL, setOpen, onSelect)}>
                <ListItemIcon>
                    <Icon path={mdiPercent} size={1} />
                </ListItemIcon>
                <ListItemText primary="Percentual" />
            </ListItem>
        );
    }
    if (evolucao) {
        tiposVisoesAsComponents.push(
            <ListItem
                button
                key="evolucao"
                onClick={onClickItem(TipoFormatoDadosRelatorio.EVOLUCAO, setOpen, onSelect)}>
                <ListItemIcon>
                    <Icon path={mdiFinance} size={1} />
                </ListItemIcon>
                <ListItemText primary="Evolução" />
            </ListItem>
        );
    }
    if (mediaProjecao) {
        tiposVisoesAsComponents.push(
            <ListItem button key="media" onClick={onClickItem(TipoFormatoDadosRelatorio.MEDIA, setOpen, onSelect)}>
                <ListItemIcon>
                    <Icon path={mdiChartLine} size={1} />
                </ListItemIcon>
                <ListItemText primary="Média / Projeção" />
            </ListItem>
        );
    }

    return (
        <>
            <IconButton onClick={() => setOpen(!open)} className={classes.eyeButton} style={{ padding: 0 }}>
                <Icon path={mdiDotsVertical} size={1} />
            </IconButton>
            <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
                <List>{tiposVisoesAsComponents}</List>
            </Drawer>
        </>
    );
};

export default FormatoDadosRelatorio;
