import { useLazyQuery } from "@apollo/react-hooks";
import { AgrupamentoRenavamQueryBuilder, FiltrosSelecionados } from "api/model";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
    DadosMarketShare,
    MarketShareHookOptions,
    MarketShareHookState,
    RetornoMarketShareHook,
    TipoDadoIncluirMarketShare,
    TODOS_TIPOS_DADOS
} from "./model";

const dadosDisponiveisNoMarketSharePadrao: TipoDadoIncluirMarketShare[] = TODOS_TIPOS_DADOS;

function carregaVolumeNoMarketShare(
    marketShare: DadosMarketShare,
    data,
    dadosDisponiveisNoMarketShare: TipoDadoIncluirMarketShare[] = dadosDisponiveisNoMarketSharePadrao
) {
    adicionadDiasUteisPeriodo(marketShare, data);
    adicionadDiasUteisMes(marketShare, data);

    dadosDisponiveisNoMarketShare.forEach(tipoDado => {
        tipoDado.adicionaVolumeMarketShare(marketShare, data);
    });
    return marketShare;
}

function adicionadDiasUteisPeriodo(marketShare: DadosMarketShare, data) {
    marketShare.quantidadeDiasUteisPeriodo = data["diasUteisMtd"];
}

function adicionadDiasUteisMes(marketShare: DadosMarketShare, data) {
    marketShare.quantidadeDiasUteisMes = data["diasUteisMes"];
}

const buscaDadosMarketShare = (
    setEstadoDoHook: Dispatch<SetStateAction<MarketShareHookState>>,
    executaQueryGraphql,
    agrupamentoRenavamQueryBuilder: AgrupamentoRenavamQueryBuilder
) => (filtro: FiltrosSelecionados) => {
    if (filtro) {
        filtro = filtro.clone();
        setEstadoDoHook((estadoAnterior: MarketShareHookState) => ({
            ...estadoAnterior,
            filtro
        }));

        executaQueryGraphql(agrupamentoRenavamQueryBuilder.getVariables(filtro));
    }
};

export const useLazyMarketShare = (options: MarketShareHookOptions): RetornoMarketShareHook => {
    const { tipoAgrupamentoMarketShare, dadosDisponiveisNoMarketShare, agrupamentoRenavam, aposCarregar } = options;
    const [estadoDoHook, setEstadoDoHook] = useState<MarketShareHookState>({});
    const agrupamentoRenavamQueryBuilder = useMemo(
        () => new AgrupamentoRenavamQueryBuilder(dadosDisponiveisNoMarketShare, agrupamentoRenavam),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [executaQueryGraphql, { loading, data }] = useLazyQuery(agrupamentoRenavamQueryBuilder.getQueries());

    useEffect(() => {
        const todosDadosCarregados = agrupamentoRenavamQueryBuilder.dadosDisponiveisNoMarketShare.every(
            tipoDado => data && data[tipoDado.getPrefixoConsulta()]
        );
        if (todosDadosCarregados && estadoDoHook.filtro) {
            const marketShare = new DadosMarketShare(
                tipoAgrupamentoMarketShare,
                estadoDoHook.filtro.getAgrupamentosMarcasDisponiveisDosAgrupamentosMarcasSelecionados() || []
            );

            carregaVolumeNoMarketShare(marketShare, data, agrupamentoRenavamQueryBuilder.dadosDisponiveisNoMarketShare);

            aposCarregar(marketShare);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return [
        buscaDadosMarketShare(setEstadoDoHook, executaQueryGraphql, agrupamentoRenavamQueryBuilder),
        {
            carregandoDadosMarketShare: loading
        }
    ];
};
