import { FiltrosSelecionados } from "api";
import { DateTime } from "luxon";
import { TipoDataClipping, TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import MarketShare from "ui/views/MarketShare/MarketShare";
import Montadora from "ui/views/Montadora/Montadora";
import Monthly from "ui/views/Monthly/Monthly";
import RegionalAnalysis from "ui/views/RegionalAnalysis/RegionalAnalysis";
import Segmento from "ui/views/Segmento/Segmento";
import SubSegmento from "ui/views/SubSegmento/SubSegmento";
import TotalIndustry from "ui/views/TotalIndustry/TotalIndustry";
import UflAnalysis from "ui/views/UfAnalysis/UfAnalysis";
import { ConfiguracaoClippingType } from "ui/components/ConfiguracaoClipping/model";

const tiposRelatoriosPeriodo = () => {
    return [
        TipoRelatorioClipping.MONTHLY,
        TipoRelatorioClipping.REGIONAL_ANALYSIS,
        TipoRelatorioClipping.TOTAL_INDUSTRY,
        TipoRelatorioClipping.UF_ANALYSIS
    ];
};

export const getComponentTypeByTipoRelatorio = (tipoRelatorio: TipoRelatorioClipping) => {
    switch (tipoRelatorio) {
        case TipoRelatorioClipping.MARKET_SHARE:
            return MarketShare;
        case TipoRelatorioClipping.TOTAL_INDUSTRY:
            return TotalIndustry;
        case TipoRelatorioClipping.SEGMENTS:
            return Segmento;
        case TipoRelatorioClipping.SUBSEGMENTS:
            return SubSegmento;
        case TipoRelatorioClipping.MONTADORA:
            return Montadora;
        case TipoRelatorioClipping.MONTHLY:
            return Monthly;
        case TipoRelatorioClipping.REGIONAL_ANALYSIS:
            return RegionalAnalysis;
        case TipoRelatorioClipping.UF_ANALYSIS:
            return UflAnalysis;
        default:
            throw new Error("Componente não configurado para o tipo de relatório");
    }
};

export const ajustaDataFiltro = (
    filtro: FiltrosSelecionados,
    tipoData: TipoDataClipping,
    tipoRelatorio: TipoRelatorioClipping
) => {
    if (tiposRelatoriosPeriodo().includes(tipoRelatorio)) {
        if (tipoData === TipoDataClipping.MES) {
            filtro.dataInicio = DateTime.fromFormat(filtro.dataFim, "yyyy-MM-dd")
                .startOf("month")
                .toFormat("yyyy-MM-dd");
        }
        if (tipoData === TipoDataClipping.ANO) {
            filtro.dataInicio = DateTime.fromFormat(filtro.dataFim, "yyyy-MM-dd")
                .startOf("year")
                .toFormat("yyyy-MM-dd");
        }
        if (tipoData === TipoDataClipping.SEIS_MESES) {
            filtro.dataInicio = DateTime.fromFormat(filtro.dataFim, "yyyy-MM-dd")
                .minus({ month: 5 })
                .toFormat("yyyy-MM-dd");
        }
        if (tipoData === TipoDataClipping.DOZE_MESES) {
            filtro.dataInicio = DateTime.fromFormat(filtro.dataFim, "yyyy-MM-dd")
                .minus({ month: 11 })
                .toFormat("yyyy-MM-dd");
        }
    }
};

export const ajustaPosicao = (dadosClipping, setItensClipping) => {
    setItensClipping(dadosClipping.sort((i1, i2) => i1.posicao - i2.posicao));
};

export const removerClipping = (
    item: ConfiguracaoClippingType,
    removerRelatorio: Function,
    dadosClipping: Array<ConfiguracaoClippingType>,
    setItensClipping: Function
) => {
    removerRelatorio({ variables: { id: item.id } });
    dadosClipping.splice(dadosClipping.indexOf(item), 1);
    ajustaPosicao(dadosClipping, setItensClipping);
};

export const ajustarPosicaoItens = (
    item: ConfiguracaoClippingType,
    subir: Boolean,
    alterarPosicaoRelatorio: Function,
    dadosClipping: Array<ConfiguracaoClippingType>,
    setItensClipping: Function
) => {
    alterarPosicaoRelatorio({ variables: { id: item.id, subir } });
    let itemAlterado: ConfiguracaoClippingType = dadosClipping.filter(i => i.id === item.id)[0];
    let itemSecundario: ConfiguracaoClippingType = dadosClipping
        .filter(i =>
            subir
                ? getPosicaoOuZero(i) < getPosicaoOuZero(itemAlterado)
                : getPosicaoOuZero(i) > getPosicaoOuZero(itemAlterado)
        )
        .sort((i1, i2) =>
            subir ? getPosicaoOuZero(i2) - getPosicaoOuZero(i1) : getPosicaoOuZero(i1) - getPosicaoOuZero(i2)
        )[0];

    if (itemAlterado && itemSecundario) {
        let posicaoAtual: number = getPosicaoOuZero(itemAlterado);
        itemAlterado.posicao = itemSecundario.posicao;
        itemSecundario.posicao = posicaoAtual;
    }

    ajustaPosicao(dadosClipping, setItensClipping);
};

const getPosicaoOuZero = (item: ConfiguracaoClippingType): number => {
    return item.posicao || 0;
};

export const atualizaClippingEditado = (dadosClipping: Array<ConfiguracaoClippingType>, setItensClipping: Function) => (
    descricaoEditada: string,
    id: number
) => {
    dadosClipping.forEach(item => {
        if (item.id === id) {
            item.descricao = descricaoEditada;
        }
    });
    setItensClipping([...dadosClipping]);
};
