interface AppProperties {
    clientName: string;
    theme: string;
    analyticsCodeProd: string;
    hotJarCode: string;
    vapidPublicKey: string;
    keycloak: string;
}

const properties = {
    responseType: "json",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
};

export const getProperties = async (urlServer): Promise<AppProperties> =>
    fetch(`${urlServer}/rest/appProperties`, properties).then(res => res.json());
