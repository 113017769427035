import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { mdiArrowDownCircle, mdiArrowUpCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { formatNumber } from "api/util/objectFunctions";
import React from "react";
import { TipoFormatoDadosRelatorio } from "ui/components/FormatoDadosRelatorio";

const useStyles = makeStyles(() => ({
    celulaTabela: {
        padding: "10px 3px 10px 3px",
        fontSize: "small",
        minWidth: "80px",
        overflowWrap: "anywhere"
    },
    conteudoCelula: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

const getDadoFormatado = (valor, formatoDadosRelatorio) => {
    if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
        return formatNumber(valor, 0);
    } else if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.EVOLUCAO) {
        return `${valor < 0 ? formatNumber(valor * -1, 2) : formatNumber(valor, 2)} %`;
    } else if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.PERCENTUAL) {
        return `${formatNumber(valor, 1)} %`;
    }
};

const getArrow = (valor, formatoDadosRelatorio) => {
    if (formatoDadosRelatorio !== TipoFormatoDadosRelatorio.EVOLUCAO) {
        return;
    }
    if (valor < 0) {
        return <Icon path={mdiArrowDownCircle} size={0.7} color="red" />;
    }
    return <Icon path={mdiArrowUpCircle} size={0.7} color="green" />;
};

const CelulaFormatada = ({ valor, formatoDadosRelatorio, style }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.celulaTabela} align="right" style={{ ...style }}>
            <div className={classes.conteudoCelula}>
                {getDadoFormatado(valor, formatoDadosRelatorio)} {getArrow(valor, formatoDadosRelatorio)}
            </div>
        </TableCell>
    );
};

const ConteudoTabelaMarketShare = ({ rows: linhas }) => {
    const classes = useStyles();
    return (
        <TableBody>
            {linhas.map(linha => {
                const fontWeight = linha.descricao === "Total" || linha.descricao === "Others" ? "bold" : undefined;

                return (
                    <TableRow key={linha.id}>
                        <TableCell className={classes.celulaTabela} style={{ fontWeight }}>
                            {linha.descricao}
                        </TableCell>
                        <CelulaFormatada
                            valor={linha.day}
                            formatoDadosRelatorio={linha.formatoDadosRelatorio}
                            style={{ fontWeight }}
                        />
                        <CelulaFormatada
                            valor={linha.mtd}
                            formatoDadosRelatorio={linha.formatoDadosRelatorio}
                            style={{ fontWeight }}
                        />
                        <CelulaFormatada
                            valor={linha.ytd}
                            formatoDadosRelatorio={linha.formatoDadosRelatorio}
                            style={{ fontWeight }}
                        />
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

export default ConteudoTabelaMarketShare;
