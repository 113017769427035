import { useQuery } from "@apollo/react-hooks";
import { Badge, Box, IconButton } from "@material-ui/core";
import { mdiBell } from "@mdi/js";
import Icon from "@mdi/react";
import { AppBar } from "@tecsinapse/ui-kit";
import { TipoAcaoApp } from "api";
import { State } from "api/redux/model";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import SelecaoMarca from "ui/components/SelecaoMarca/SelecaoMarca";
import { quantidadeNotificacoesNaoLidas } from "ui/components/ViewLayout/queries";

const BarraSuperiorApp = ({ title }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { usuarioLogado, marcaSelecionada, notificacoesNaoLidas } = useSelector((state: State) => state.app);

    const { stopPolling, data, refetch } = useQuery(quantidadeNotificacoesNaoLidas, {
        variables: { email: usuarioLogado.email, montadora: marcaSelecionada },
        pollInterval: 30000,
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, [stopPolling]);

    useEffect(() => {
        if (data && data.quantidadeNotificacoesNaoLidas) {
            dispatch({
                type: TipoAcaoApp.SET_NOTIFICACOES_NAO_LIDAS,
                data: data.quantidadeNotificacoesNaoLidas
            });
        }
    }, [data, dispatch]);

    useEffect(() => {
        refetch().then(resultadoNotificacoesNaoLidas => {
            if (resultadoNotificacoesNaoLidas.data) {
                dispatch({
                    type: TipoAcaoApp.SET_NOTIFICACOES_NAO_LIDAS,
                    data: resultadoNotificacoesNaoLidas.data.quantidadeNotificacoesNaoLidas
                });
            }
        });
    }, [marcaSelecionada, dispatch, refetch]);

    return (
        <Box position="fixed" top={0} width="100%" zIndex={1100}>
            <AppBar
                menuBar={false}
                disableBreadcrumb
                title={title}
                leftIcons={<SelecaoMarca />}
                rightIcons={
                    <IconButton onClick={() => history.replace("/notificacoes")}>
                        <Badge badgeContent={notificacoesNaoLidas} color="secondary">
                            <Icon path={mdiBell} size={1} color="white" />
                        </Badge>
                    </IconButton>
                }
            />
        </Box>
    );
};

export default BarraSuperiorApp;
