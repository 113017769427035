import { Box, Button, CircularProgress } from "@material-ui/core";
import React, { MouseEventHandler } from "react";

interface SubmitButtonProps {
    isSubmitting?: boolean;
    onClick?: MouseEventHandler<any>;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ children, isSubmitting = false, onClick }) => {
    return (
        <Box position="relative">
            <Button
                onClick={onClick}
                variant="contained"
                fullWidth
                disableElevation={true}
                color="secondary"
                type="submit"
                disabled={isSubmitting}>
                {children}
                {isSubmitting ? (
                    <Box position="absolute" display="flex" alignContent="center" justifyContent="center">
                        <CircularProgress size={24} />
                    </Box>
                ) : null}
            </Button>
        </Box>
    );
};

export { SubmitButton };
export default SubmitButton;
