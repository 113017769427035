import { useLazyQuery } from "@apollo/react-hooks";
import { criaConsultaAgrupamentoRenavam } from "api/graphql";
import { AgrupamentoRenavamQuery, FiltrosSelecionados } from "api/model";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import { TipoFormatoDadosRelatorio } from "ui/components/FormatoDadosRelatorio/model/TipoFormatoDadosRelatorio";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";
import { calculaEGeraGraficoUfAnalysis } from "./util/criaGrafico";

const agrupamentoRenavam = new AgrupamentoRenavamQuery();
agrupamentoRenavam.comMarcaNome();
agrupamentoRenavam.comCidadeEstado();
agrupamentoRenavam.comMarcaCor();
agrupamentoRenavam.comQuantidade();

const customizaFiltro = (filtro: FiltrosSelecionados) => {
    filtro.areasAtuacoesSelecionadas = [];
    filtro.dataInicio = DateTime.fromFormat(filtro.dataInicio, "yyyy-MM-dd")
        .startOf("year")
        .toFormat("yyyy-MM-dd");
    filtro.disponivelApenasAgrupamentoDaMontadora();
};

const aplicarFiltro = (executaConsulta, setFiltrosSelecionados) => (filtroSelecionado: FiltrosSelecionados) => {
    executaConsulta({ variables: { filtro: filtroSelecionado.selecionadosAsString() } });
    setFiltrosSelecionados(filtroSelecionado);
};

const UflAnalysis: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const [tipoFormatoDadosRelatorio, setTipoFormatoDadosRelatorio] = useState(TipoFormatoDadosRelatorio.ABSOLUTO);

    const [filtroSelecionado, setFiltroSelecionado] = useState<FiltrosSelecionados>();

    const chartRef = useRef<any>(null);

    const [dadosRelatorio, setDadosRelatorio] = useState();

    const [executaQueryGraphql, { loading, data }] = useLazyQuery(
        criaConsultaAgrupamentoRenavam("ufAnalysis", agrupamentoRenavam)
    );

    useEffect(() => {
        setDadosRelatorio(data);
    }, [data]);

    useEffect(() => {
        if (filtro) {
            aplicarFiltro(executaQueryGraphql, setFiltroSelecionado)(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (chartRef && chartRef.current && chartRef.current.chart) {
        if (loading) {
            chartRef.current.chart.showLoading();
        } else {
            chartRef.current.chart.hideLoading();
        }
    }

    return (
        <ViewLayout
            titulo="Uf Analysis"
            descricao={
                "Emplacamentos por estado. As marcas selecionadas serão utilizadas na visão percentual para cálculo do Market Share e Média Nacional."
            }
            carregando={loading}
            filtroProps={{
                customizaFiltro: customizaFiltro,
                aplicarFiltro: aplicarFiltro(executaQueryGraphql, setFiltroSelecionado)
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.UF_ANALYSIS,
                filtrosSelecionados: filtroSelecionado
            }}
            formatoDadosRelatorioProps={{
                onSelect: setTipoFormatoDadosRelatorio,
                mediaProjecao: false,
                evolucao: false
            }}
            {...viewLayoutProps}>
            <HighchartsReact
                highcharts={Highcharts}
                options={calculaEGeraGraficoUfAnalysis(dadosRelatorio, tipoFormatoDadosRelatorio, filtroSelecionado)}
                ref={chartRef}
            />
        </ViewLayout>
    );
};

export default UflAnalysis;
