import gql from "graphql-tag";
import { AgrupamentoRenavamQuery } from "../../model/AgrupamentoRenavamQuery";

export function criaConsultaAgrupamentoRenavam(
    queryName: String,
    agrupamentoRenavam: AgrupamentoRenavamQuery
) {
    return gql`query ${queryName}($filtro: FiltroAgrupamentoRenavam!) {
				agrupamentoRenavam(filtro: $filtro) {
					${agrupamentoRenavam.graphqlCamposConsulta()}
				}
			}`;
}
