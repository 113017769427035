import { useApolloClient } from "@apollo/react-hooks";
import { styled } from "@material-ui/styles";
import { configuracoesIniciasAppQuery } from "api/graphql/queries/configuracoesIniciasAppQuery";
import { TipoAcaoApp } from "api/model";
import { State } from "api/redux/model";
import MenuApp from "app/MenuApp/MenuApp";
import React, { useEffect, useLayoutEffect } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Router } from "routes/Router";
import SplashScreen from "ui/components/SplashScreen/SplashScreen";
import BarraSuperiorApp from "ui/components/ViewLayout/BarraSuperiorApp";
import { usuarioPorEmail } from "./queries/usuarioLogadoQuery";

const StyledAppLayout = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#F5F5F5"
});

const usePageTracker = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location.pathname]);
};

const App = ({ properties, keycloak, clientConfig }) => {
    const state = useSelector((state: State) => state.app);
    const dispatch = useDispatch();
    const client = useApolloClient();
    usePageTracker();
    useLayoutEffect(() => {
        (async () => {
            const usuarioPorEmailResult = await client.query(usuarioPorEmail(keycloak.profile.email));

            const usuarioLogado = usuarioPorEmailResult.data.usuarioPorEmail;

            const dadosIniciaisResult = await client.query(
                configuracoesIniciasAppQuery(usuarioLogado.marcas[0], usuarioLogado.email)
            );

            const notificacoesAtivas = await navigator.serviceWorker.ready
                .then(registration => registration.pushManager.getSubscription())
                .then(subs => {
                    return !!subs;
                })
                .catch(console.error);

            dispatch({
                type: TipoAcaoApp.SET_URL_SERVER,
                data: clientConfig.url
            });

            dispatch({
                type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS,
                data: notificacoesAtivas
            });

            dispatch({
                type: TipoAcaoApp.SET_APP_PROPERTIES,
                data: properties
            });

            dispatch({
                type: TipoAcaoApp.SET_DADOS_INICIAIS_FILTRO,
                data: dadosIniciaisResult.data.dadosIniciaisFiltro
            });

            dispatch({
                type: TipoAcaoApp.SET_LOGGED_USER,
                data: usuarioLogado
            });

            dispatch({
                type: TipoAcaoApp.LAST_DATA_UPDATE,
                data: dadosIniciaisResult.data.ultimaDataEmplacamento
            });

            dispatch({
                type: TipoAcaoApp.SELECT_BRAND,
                data: usuarioLogado.marcas[0]
            });

            dispatch({
                type: TipoAcaoApp.SET_TIPOS_NOTIFICOES_USUARIO,
                data: dadosIniciaisResult.data.tiposPushSubscriptionsUsuario
            });

            dispatch({
                type: TipoAcaoApp.SET_LABELS,
                data: dadosIniciaisResult.data.labelsApp
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.ultimaDataEmplacamento || !state.marcaSelecionada) {
        return <SplashScreen client={clientConfig.client} />;
    }

    return (
        <>
            <StyledAppLayout>
                <BarraSuperiorApp title="" />
                <Router />
                <MenuApp />
            </StyledAppLayout>
        </>
    );
};

export default App;
