import { State } from "api/redux/model";
import labelReducer from "api/redux/reducers/labelReducer";
import { combineReducers } from "redux";
import appReducer from "./appReducer";
import filtroReducer from "./filtroReducer";

const rootReducers = combineReducers<State>({
    app: appReducer,
    filtro: filtroReducer,
    labels: labelReducer
});

export default rootReducers;
