import { useLazyQuery } from "@apollo/react-hooks";
import { Tab, Tabs } from "@material-ui/core";
import { criaConsultaAgrupamentoRenavam, Regiao, Regioes } from "api";
import { AgrupamentoRenavamQuery, FiltrosSelecionados } from "api/model";
import Highcharts, { PointOptionsObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { TipoFormatoDadosRelatorio } from "ui/components";
import { TipoRelatorioClipping } from "ui/components/ConfiguracaoClipping/enums";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { RelatorioProps } from "ui/views/Props/RelatorioProps";
import { grafico } from "./model/GraficoRegionalAnalysis";
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);

const agrupamentoRenavam = new AgrupamentoRenavamQuery();
agrupamentoRenavam.comMarcaNome();
agrupamentoRenavam.comMarcaCor();
agrupamentoRenavam.comCidadeEstado();
agrupamentoRenavam.comQuantidade();

const renderizaNovoGrafico = (
    regiaoSelecionada: Regiao,
    formatoDadosRelatorio: TipoFormatoDadosRelatorio,
    graficosPorRegiao: Map<String, Highcharts.Options>,
    setOpcoesGrafico
) => {
    const opcoesGrafico = cloneDeep(graficosPorRegiao.get(regiaoSelecionada.nome) || {});

    if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.ABSOLUTO) {
        if (opcoesGrafico.tooltip?.pointFormat) {
            opcoesGrafico.tooltip.pointFormat = "{series.name}: <b>{point.percentage: .1f} %</b>";
        }
        if (opcoesGrafico.plotOptions?.pie?.dataLabels) {
            opcoesGrafico.plotOptions.pie.dataLabels["format"] = "<b>{point.name}</b>: {point.y}";
        }
    }
    if (formatoDadosRelatorio === TipoFormatoDadosRelatorio.PERCENTUAL) {
        if (opcoesGrafico.tooltip?.pointFormat) {
            opcoesGrafico["tooltip"]["pointFormat"] = "<b>{series.name}</b>: {point.y: .1f}";
        }
        if (opcoesGrafico.plotOptions?.pie?.dataLabels) {
            opcoesGrafico.plotOptions.pie.dataLabels["format"] = "{point.name}: <b>{point.percentage: .1f} %</b>";
        }
    }

    setOpcoesGrafico(opcoesGrafico);
};

const atualizaOpcoesGrafico = (setGraficosPorRegiao, filtro?: FiltrosSelecionados, data?) => {
    if (!filtro || !data) {
        return;
    }

    const usarAgrupadorMarcas = filtro.agrupamentoMarcasSelecionados.length > 0;
    const graficosPorRegiao = new Map<String, Highcharts.Options>();

    for (let regiao of Regioes.regioes) {
        let quantidadePorMarca = {};
        let corPorMarca = {};

        data.agrupamentoRenavam
            .filter(renavam => regiao.estados.some(estado => estado === renavam.cidade_estado))
            .sort((r1, r2) => r2.quantidade - r1.quantidade)
            .forEach(renavam => {
                let atributoNome = renavam.marca_nome;
                let cor = renavam.marca_cor;

                if (usarAgrupadorMarcas) {
                    let agrupamento = filtro
                        .getAgrupamentosMarcasDisponiveisDosAgrupamentosMarcasSelecionados()
                        .filter(agrupamento => agrupamento.marcas.some(marca => marca.nome === renavam.marca_nome))[0];
                    if (agrupamento) {
                        atributoNome = agrupamento.nome;
                        cor = agrupamento.cor;
                    }
                }

                let quantidadeAnterior = quantidadePorMarca[atributoNome] ? quantidadePorMarca[atributoNome] : 0;

                quantidadePorMarca[atributoNome] = quantidadeAnterior + renavam.quantidade;
                corPorMarca[atributoNome] = cor;
            });

        let dadosGrafico: PointOptionsObject[] = [];
        for (let key in quantidadePorMarca) {
            dadosGrafico.push({
                name: key,
                y: quantidadePorMarca[key],
                color: "#" + corPorMarca[key]
            });
        }
        const novasOpcoesGrafico = cloneDeep(grafico);
        if(dadosGrafico.length > 0){
            novasOpcoesGrafico.series = [
                {
                    type: "pie",
                    name: "Percentual",
                    colorByPoint: true,
                    data: dadosGrafico
                }
            ];
        }else{
            novasOpcoesGrafico.series = [];
        }
        
        graficosPorRegiao.set(regiao.nome, novasOpcoesGrafico);
    }
    setGraficosPorRegiao(graficosPorRegiao);
};

const aplicarFiltro = (carregaDadosRelatorio, setFiltrosSelecionados) => (filtro: FiltrosSelecionados) => {
    setFiltrosSelecionados(filtro);
    carregaDadosRelatorio({
        variables: {
            filtro: filtro.selecionadosAsString()
        }
    });
};

const customizaFiltro = (filtro: FiltrosSelecionados) => {
    filtro.areasAtuacoesSelecionadas = [];
};

const RegionalAnalysis: React.FC<RelatorioProps> = ({ filtro, viewLayoutProps }) => {
    const [formatoDadosRelatorio, setFormatoDadosRelatorio] = useState(TipoFormatoDadosRelatorio.ABSOLUTO);
    const [regiaoSelecionada, setRegiaoSelecionada] = useState(Regioes.SUL);
    const [opcoesGrafico, setOpcoesGrafico] = useState<Highcharts.Options>(grafico);
    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltrosSelecionados>();
    const [graficosPorRegiao, setGraficosPorRegiao] = useState<Map<String, Highcharts.Options>>(new Map());
    const chartRef = useRef<any>(null);

    const [carregaDadosRelatorio, { loading, data }] = useLazyQuery(
        criaConsultaAgrupamentoRenavam("regionalAnalysis", agrupamentoRenavam)
    );

    useEffect(() => {
        renderizaNovoGrafico(regiaoSelecionada, formatoDadosRelatorio, graficosPorRegiao, setOpcoesGrafico);
    }, [regiaoSelecionada, formatoDadosRelatorio, graficosPorRegiao]);

    useEffect(() => {
        atualizaOpcoesGrafico(setGraficosPorRegiao, filtrosSelecionados, data);
    }, [filtrosSelecionados, data]);

    useEffect(() => {
        if (filtro) {
            aplicarFiltro(carregaDadosRelatorio, setFiltrosSelecionados)(filtro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ViewLayout
            carregando={loading}
            titulo="Regional Analysis"
            descricao={"Market share em cada uma das regiões do Brasil."}
            filtroProps={{
                customizaFiltro,
                aplicarFiltro: aplicarFiltro(carregaDadosRelatorio, setFiltrosSelecionados)
            }}
            clippingProps={{
                tipoRelatorio: TipoRelatorioClipping.REGIONAL_ANALYSIS,
                filtrosSelecionados
            }}
            formatoDadosRelatorioProps={{
                onSelect: setFormatoDadosRelatorio,
                mediaProjecao: false,
                evolucao: false
            }}
            {...viewLayoutProps}>
            <Tabs
                value={regiaoSelecionada}
                onChange={(event, regiaoSelecionada: Regiao) => setRegiaoSelecionada(regiaoSelecionada)}
                aria-label="regiao tabs"
                scrollButtons="auto"
                variant="scrollable">
                <Tab label="Sul" value={Regioes.SUL} fullWidth={true} />
                <Tab label="Sudeste" value={Regioes.SUDESTE} fullWidth={true} />
                <Tab label="Centro Oeste" value={Regioes.CENTRO_OESTE} fullWidth={true} />
                <Tab label="Nordeste" value={Regioes.NORDESTE} fullWidth={true} />
                <Tab label="Norte" value={Regioes.NORTE} fullWidth={true} />
            </Tabs>

            <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} ref={chartRef} />
        </ViewLayout>
    );
};

export default RegionalAnalysis;
