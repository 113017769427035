import { TipoMontadora } from "../enums/TipoMontadora";

export class Montadora {
    constructor(
        private tipoMontadora: TipoMontadora,
        public descricao: string,
        public logoSelecao: string,
        public logo: string,
        public sigla: string,
        public enumName: string
    ) {}

    getLogo(): string {
        return this.logo;
    }

    getLogoSelecao(): string {
        return this.logoSelecao;
    }
}
