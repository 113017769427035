export const OpcoesGraficoMonthly: Highcharts.Options = {
    title: {
        text: ""
    },

    xAxis: {
        categories: []
    },

    yAxis: {
        title: {
            text: "Quantidade"
        }
    },

    series: [
        {
            name: " ",
            type: "column",
            data: [],
            showInLegend: false
        }
    ],

    credits: {
        enabled: false
    },

    exporting: { enabled: false }
};
