import { makeStyles, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
    celulaTabela: {
        padding: "10px 3px 10px 3px",
        fontSize: "small",
        minWidth: "80px"
    }
}));

const CabecalhoTabelaMarketShare = ({ colunas }) => {
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {colunas &&
                    colunas.map(coluna => (
                        <TableCell key={coluna.title} align={coluna.align} className={classes.celulaTabela}>
                            {coluna.title}
                            <br />
                            {coluna.value}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

export default CabecalhoTabelaMarketShare;
