import { ReduxAction, TipoAcaoApp } from "api/model";
import { cloneDeep } from "lodash";
import { AppState } from "../model/AppState";

export const INITIAL_STATE: AppState = {
    urlServer: "",
    usuarioLogado: {
        nome: "",
        email: "",
        marcas: [""]
    },
    marcaSelecionada: "",
    ultimaDataEmplacamento: "",
    configuracoes: {
        tiposNotificoes: [],
        notificacoesAtivas: false
    },
    appProperties: {
        analyticsCodeProd: "",
        clientName: "",
        hotJarCode: "",
        theme: "",
        vapidPublicKey: ""
    },
    notificacoesNaoLidas: 0
};

const appReducer = (state: AppState = INITIAL_STATE, action: ReduxAction): AppState => {
    const novoEstado = cloneDeep(state);
    if (action.type === TipoAcaoApp.SET_URL_SERVER) {
        novoEstado.urlServer = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SET_APP_PROPERTIES) {
        novoEstado.appProperties = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SELECT_BRAND) {
        novoEstado.marcaSelecionada = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.LAST_DATA_UPDATE) {
        novoEstado.ultimaDataEmplacamento = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SET_LOGGED_USER) {
        novoEstado.usuarioLogado = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SET_NOTIFICACOES_ATIVAS) {
        novoEstado.configuracoes.notificacoesAtivas = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SET_TIPOS_NOTIFICOES_USUARIO) {
        novoEstado.configuracoes.tiposNotificoes = action.data || [];
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SET_TIPO_NOTIFICAO_USUARIO) {
        novoEstado.configuracoes.tiposNotificoes.push(action.data);
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.REMOVE_TIPO_NOTIFICAO_USUARIO) {
        novoEstado.configuracoes.tiposNotificoes = novoEstado.configuracoes.tiposNotificoes.filter(
            notificacao => notificacao !== action.data
        );
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.SET_NOTIFICACOES_NAO_LIDAS) {
        novoEstado.notificacoesNaoLidas = action.data;
        return novoEstado;
    }
    if (action.type === TipoAcaoApp.DECREMENTA_NOTIFICACAO_NAO_LIDA) {
        novoEstado.notificacoesNaoLidas = novoEstado.notificacoesNaoLidas > 0 ? novoEstado.notificacoesNaoLidas - 1 : 0;
        return novoEstado;
    }
    return state;
};

export default appReducer;
