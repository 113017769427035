import { Box, CircularProgress, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import React from "react";

interface LoadingViewLayoutProps {
    loading: boolean;
    fullScreenLoading?: boolean;
}

const Loading = styled(Box)({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 1000,
    borderRadius: 4,
    backgroundColor: "rgba(0, 0, 0, 0.3)"
});

const FullScreenLoading = styled(Box)({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)"
});

const LoadingViewLayout: React.FC<LoadingViewLayoutProps> = ({ loading = false, fullScreenLoading = true }) => {
    if (!loading) return null;

    const LoadingComponent = fullScreenLoading ? FullScreenLoading : Loading;

    return (
        <LoadingComponent>
            <Paper
                elevation={0}
                style={{
                    width: "70%",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    maxWidth: "300px",
                    minWidth: "250px"
                }}>
                <CircularProgress color="secondary" />
                <div style={{ marginLeft: 15 }}>Carregando informações...</div>
            </Paper>
        </LoadingComponent>
    );
};

export default LoadingViewLayout;
