import { withApollo } from "@apollo/react-hoc";
import { Box, Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { hideGlobalLoading, showGlobalLoading } from "@tecsinapse/react-boilerplate/build/ui/globalLoading";
import { configuracoesIniciasAppQuery } from "api/graphql";
import { Montadora, Montadoras, TipoAcaoApp } from "api/model";
import { State } from "api/redux/model/State";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
    div: {
        alignSelf: "stretch",
        display: "flex"
    },
    flex: {
        alignItems: "center",
        display: "flex"
    }
}));

interface Props {
    client: any;
}

const selectBrand = (setOpen, client, brand, dispatch, email) => async () => {
    showGlobalLoading();
    const dadosIniciaisResult = await client.query(configuracoesIniciasAppQuery(brand.enumName, email));

    dispatch({
        type: TipoAcaoApp.SET_DADOS_INICIAIS_FILTRO,
        data: dadosIniciaisResult.data.dadosIniciaisFiltro
    });

    dispatch({ type: TipoAcaoApp.SELECT_BRAND, data: brand.enumName });

    dispatch({
        type: TipoAcaoApp.LAST_DATA_UPDATE,
        data: dadosIniciaisResult.data.ultimaDataEmplacamento
    });

    dispatch({
        type: TipoAcaoApp.SET_LABELS,
        data: dadosIniciaisResult.data.labelsApp
    });

    hideGlobalLoading();
    setOpen(false);
};

const abreSelecaoDeMarcas = (setOpen, marcasDisponiveis: Montadora[]) => () => {
    if (marcasDisponiveis.length > 1) {
        setOpen(true);
    }
};

const SelecaoMarca: React.FC<Props> = ({ client }) => {
    const appState = useSelector((state: State) => state.app);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const marcarSelecionada = Montadoras.getByTipo(appState.marcaSelecionada);
    const marcasDisponiveis = appState.usuarioLogado.marcas
        .map(brand => Montadoras.getByTipo(brand))
        .sort((m1, m2) => (m1.enumName > m2.enumName ? 1 : -1));
    const possueMaisDeUmaMarcaDisponivel = marcasDisponiveis.length > 1;
    const logoBarraSuperior = possueMaisDeUmaMarcaDisponivel ? marcarSelecionada.getLogo() : "/img/logo-emplacar.png";

    const classes = useStyles();

    return (
        <>
            <div className={classes.div} onClick={abreSelecaoDeMarcas(setOpen, marcasDisponiveis)}>
                <div className={classes.flex}>
                    <img
                        src={logoBarraSuperior}
                        style={{ maxHeight: possueMaisDeUmaMarcaDisponivel ? 36 : 28 }}
                        alt="logo"
                    />
                </div>
            </div>
            <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
                <Box display="flex" flex={1} flexDirection="column" alignItems="center">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box alignSelf="flex-end">
                            <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                                <Icon path={mdiClose} size={1} />
                            </IconButton>
                        </Box>
                        <Box alignSelf="center">
                            <Typography variant="subtitle2">Selecione a marca desejada</Typography>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-evenly"
                        alignItems="center"
                        flex={1}>
                        {marcasDisponiveis.map(marca => (
                            <img
                                key={marca.enumName}
                                src={marca.getLogoSelecao()}
                                alt="logo"
                                style={{ opacity: marca === marcarSelecionada ? 0.5 : 1, maxHeight: "82px" }}
                                onClick={selectBrand(setOpen, client, marca, dispatch, appState.usuarioLogado.email)}
                            />
                        ))}
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

export default withApollo(SelecaoMarca);
