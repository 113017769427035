import { Box, Typography } from "@material-ui/core";
import ThemeProvider from "@tecsinapse/ui-kit/build/ThemeProvider";
import React from "react";

export function ServerOfflineScreen() {
    return (
        <ThemeProvider variant="orange">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                padding={1}
                color="#808080"
                height="100vh">
                <Box>
                    <a href={window.location.href}>
                        <img
                            src={`${process.env.PUBLIC_URL}/img/emplacamento.png`}
                            alt="logo-emplacar"
                            style={{ maxHeight: 36 }}
                        />
                    </a>
                </Box>
                <Typography variant="h5">Atualização em Curso</Typography>
                <Typography variant="body2" component="p" align="center">
                    Estamos realizando uma atualização na aplicação.
                </Typography>
                <Typography variant="body2" component="p" align="center">
                    Por favor, volte novamente em instantes.
                </Typography>
                <Typography variant="caption" color="inherit">
                    Equipe TecSinapse.
                </Typography>
            </Box>
        </ThemeProvider>
    );
}
