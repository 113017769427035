import { Montadora } from "api/model";
import gql from "graphql-tag";

export function configuracoesIniciasAppQuery(montadora: Montadora, email: string) {
    let query = gql`
        query dadosIniciaisFiltro($montadora: Montadora!, $email: String!) {
            tiposPushSubscriptionsUsuario(email: $email)
            ultimaDataEmplacamento(montadora: $montadora)
            labelsApp(montadora: $montadora) {
                filtro {
                    tipoModelo
                    conceito
                    segmento
                    segmentoMarketing
                    areaAtuacao
                }
            }
            dadosIniciaisFiltro(montadora: $montadora, email: $email) {
                marcaDaMontadora {
                    codigo
                    nome
                }
                tiposModelosDisponiveis {
                    nome
                    codigo
                    padrao
                }
                conceitosDisponiveis {
                    nome
                    codigo
                    padrao
                }
                segmentosDisponiveis {
                    nome
                    codigo
                    padrao
                }
                segmentosMarketingDisponiveis {
                    nome
                    codigo
                    padrao
                }
                agrupamentosMarcasDisponiveis {
                    id
                    nome
                    cor
                    marcas {
                        codigo
                        nome
                    }
                    padrao
                }
                marcasDisponiveis {
                    nome
                    codigo
                    padrao
                }
                divisoesRegionaisDisponiveis {
                    nome
                    pk
                    padrao
                }
                areasAtuacaoDisponiveis {
                    nome
                    pk
                    padrao
                }
            }
        }
    `;

    return {
        query,
        variables: {
            montadora,
            email
        }
    };
}
