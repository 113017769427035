import { QueryLazyOptions } from "@apollo/react-hooks";
import { TipoDadoIncluirMarketShare } from "api/hooks/useMarketShare/model";
import { OperationVariables } from "apollo-client";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { FiltrosSelecionados } from "ui/components/Filtro/model";
import { AgrupamentoRenavamQuery } from "./AgrupamentoRenavamQuery";

export class AgrupamentoRenavamQueryBuilder {

    private queries: AgrupamentoRenavamQuery[] = [];

    constructor(public dadosDisponiveisNoMarketShare: TipoDadoIncluirMarketShare[], agrupamento: AgrupamentoRenavamQuery) {
        dadosDisponiveisNoMarketShare.forEach(tipoDado => {
            this.adicionaQuery(
                agrupamento.cloneComTipoDado(tipoDado)
            );
        });
    }

    adicionaQuery(
        agrupamentoRenavamQuery: AgrupamentoRenavamQuery
    ): AgrupamentoRenavamQueryBuilder {
        this.queries.push(agrupamentoRenavamQuery);
        return this;
    }

    getVariables(filtro: FiltrosSelecionados): QueryLazyOptions<OperationVariables> {
        let variable = {};
        this.queries.forEach(query => {
            variable[query.getFiltroName()] = query.tipoDado?.getFiltroCustomizado(filtro).selecionadosAsString();
        });

        const dataInicio = DateTime.fromFormat(filtro.dataInicio, "yyyy-MM-dd")
        .startOf("month")
        .toFormat("yyyy-MM-dd");

        const dataFimMes = DateTime.fromFormat(filtro.dataFim, "yyyy-MM-dd")
        .endOf("month")
        .toFormat("yyyy-MM-dd");

        variable['dataInicioMes'] = dataInicio;
        variable['dataFimMes'] = dataFimMes;
        variable['dataInicioMtd'] = dataInicio;
        variable['dataFimMtd'] = filtro.dataFim;
        return {
            variables: variable
        };
    }

    getQueries(): Object {
        let parametros: String[] = [];
        let queryDefinitions: Object[] = [];

        queryDefinitions.push(`diasUteisMes: diasUteisPeriodo(dataInicio: $dataInicioMes, dataFim: $dataFimMes)`);
        parametros.push(`$dataInicioMes: Date!`);
        parametros.push(`$dataFimMes: Date!`);

        queryDefinitions.push(`diasUteisMtd: diasUteisPeriodo(dataInicio: $dataInicioMtd, dataFim: $dataFimMtd)`);
        parametros.push(`$dataInicioMtd: Date!`);
        parametros.push(`$dataFimMtd: Date!`);

        this.queries.forEach(query => {
            const nomeFiltro = query.getFiltroName();

            parametros.push(`$${nomeFiltro} : FiltroAgrupamentoRenavam!`);

            queryDefinitions.push(`${
                query.tipoDado?.getPrefixoConsulta()
            } : agrupamentoRenavam(filtro: $${nomeFiltro})
                { ${query.graphqlCamposConsulta()} }`);
        });

        return gql`${`query AgrupamentoRenavam(${parametros}) {${queryDefinitions} }`}`;
    }
}
