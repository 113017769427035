import React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "./Routes";

export const Router = () => (
    <Switch>
        {Object.keys(Routes)
            .filter(key => {
                if (!Routes[key].component) {
                    return false;
                }
                return true;
            })
            .map(key => {
                const { route, component: Component } = Routes[key];
                return <Route key={key} path={route} exact render={() => <Component />} />;
            })}
    </Switch>
);
