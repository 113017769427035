import { AgrupamentoRenavamQuery, FiltrosSelecionados } from "api";
import { TipoUsuario } from "api/enums/TipoUsuario";
import { Usuario } from "api/model/Usuario";

export const getDescricaoRelatorio = (usuarioLogado: Usuario) => {
    if (usuarioLogado.tipoUsuario === TipoUsuario.DEALER_GRUPO) {
        return "Ranking por marca e por modelo da indústria completa, incluindo concorrentes indiretos. Como padrão vem filtrado apenas a área de atuação do dealer.";
    }
    return "Ranking por marca e por modelo da indústria completa, incluindo concorrentes indiretos.";
};

export const criaAgrupamentoMarca = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comMarcaNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

export const criaAgrupamentoGrupoModelo = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comGrupoModeloNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

export const criaAgrupamentoModelo = () => {
    const agrupamentoMarca = new AgrupamentoRenavamQuery();
    agrupamentoMarca.comModeloNome();
    agrupamentoMarca.comQuantidade();
    return agrupamentoMarca;
};

export const customizaFiltro = (filtro: FiltrosSelecionados) => {
    filtro.selecionarTodosTiposModelosConceitosSegmentosEMarcas();
};
