import { Box } from "@material-ui/core";
import { mdiClockOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { State } from "api/redux/model";
import React from "react";
import { useSelector } from "react-redux";

const UltimaDataEmplacamento = () => {
    const ultimaDataEmplacamento = useSelector((app: State) => app.app.ultimaDataEmplacamento);
    return (
        <Box width="100%" display="flex" alignItems="center" paddingTop={1} paddingLeft={1} paddingRight={1}>
            <Icon path={mdiClockOutline} size={0.8} style={{ marginRight: 5 }} />
            Última atualização: {ultimaDataEmplacamento}
        </Box>
    );
};

export default UltimaDataEmplacamento;
