import { TipoDadoIncluirMarketShare } from "api/hooks/useMarketShare/model";
import { JsonMember, JsonObject, TypedJSON } from "typedjson-npm";
import { FiltrosSelecionados } from "../../ui/components/Filtro/model/FiltrosSelecionados";

@JsonObject
export class AgrupamentoRenavamQuery {
    @JsonMember({ type: Boolean })
    private data_emplacamento: Boolean = false;

    @JsonMember({ type: Boolean })
    private numero_chassi: Boolean = false;

    @JsonMember({ type: Boolean })
    private ano_fabricacao: Boolean = false;

    @JsonMember({ type: Boolean })
    private combustivel: Boolean = false;

    @JsonMember({ type: Boolean })
    private potencia: Boolean = false;

    @JsonMember({ type: Boolean })
    private cnpj: Boolean = false;

    @JsonMember({ type: Boolean })
    private codigo_renavam: Boolean = false;

    @JsonMember({ type: Boolean })
    private cidade_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private cidade_estado: Boolean = false;

    @JsonMember({ type: Boolean })
    private marca_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private marca_cor: Boolean = false;

    @JsonMember({ type: Boolean })
    private segmento_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private conceito_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private tipo_modelo_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private segmento_marketing_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private quantidade: Boolean = false;

    @JsonMember({ type: Boolean })
    private ano_mes: Boolean = false;

    @JsonMember({ type: Boolean })
    private modelo_nome: Boolean = false;

    @JsonMember({ type: Boolean })
    private grupo_modelo_nome: Boolean = false;

    public tipoDado?: TipoDadoIncluirMarketShare;

    comDataEmplacamento() {
        this.data_emplacamento = true;
    }

    comNumeroChassi() {
        this.numero_chassi = true;
    }

    comAnoFabricacao() {
        this.ano_fabricacao = true;
    }

    comCombustivel() {
        this.combustivel = true;
    }

    comPotencia() {
        this.potencia = true;
    }

    comCnpj() {
        this.cnpj = true;
    }

    comCodigoRenavam() {
        this.codigo_renavam = true;
    }

    comCidadeNome() {
        this.cidade_nome = true;
    }

    comCidadeEstado() {
        this.cidade_estado = true;
    }

    comMarcaNome() {
        this.marca_nome = true;
    }

    comMarcaCor() {
        this.marca_cor = true;
    }

    comSegmentoNome() {
        this.segmento_nome = true;
    }

    comConceitoNome() {
        this.conceito_nome = true;
    }

    comTipoModeloNome() {
        this.tipo_modelo_nome = true;
    }

    comSegmentoMarketingNome() {
        this.segmento_marketing_nome = true;
    }

    comQuantidade() {
        this.quantidade = true;
    }

    comAnoMes() {
        this.ano_mes = true;
    }

    comModeloNome() {
        this.modelo_nome = true;
    }

    comGrupoModeloNome() {
        this.grupo_modelo_nome = true;
    }

    getFiltroName() {
        return "filtro_" + this.tipoDado?.getPrefixoConsulta();
    }

    graphqlCamposConsulta() {
        return Object.keys(this)
            .filter(key => this[key] === true)
            .join(",");
    }

    public asString(): string {
        return TypedJSON.stringify(this);
    }

    public clone(): AgrupamentoRenavamQuery {
        return TypedJSON.parse(this.asString(), AgrupamentoRenavamQuery);
    }

    public cloneComTipoDado(
        tipoDado: TipoDadoIncluirMarketShare
    ): AgrupamentoRenavamQuery {
        const novoQueryBuilder = this.clone();
        novoQueryBuilder.tipoDado = tipoDado;
        return novoQueryBuilder;
    }

    public getFiltroAsString(filtro: FiltrosSelecionados): string {
        if (!filtro) {
            return "";
        }
        return filtro.selecionadosAsString();
    }
}
