export const group = (array, keyFunc, fieldsToAggregate) => {
    return array.reduce((previousValue, currentValue, currentIndex, originalArray) => {
        const key = keyFunc(currentValue);

        if (previousValue.lenght === 0 || !previousValue.some(value => isEquivalent(keyFunc(value), key))) {
            const newValue = Object.assign({}, currentValue);
            previousValue.push(newValue);
            return previousValue;
        }

        previousValue.forEach(value => {
            if (isEquivalent(keyFunc(value), key)) {
                fieldsToAggregate.forEach(field => {
                    value[field] = value[field] + currentValue[field];
                });
            }
        });

        return previousValue;
    }, []);
};

export const isEquivalent = (a, b) => {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
};

export function formatNumber(n: number, maximumFractionDigits: number): string {
    return n.toLocaleString(["pt", "br"], { maximumFractionDigits });
}
