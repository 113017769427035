import { TipoAcaoApp } from "api";
import { TipoNotificacaoApp } from "ui/views/Configuracoes/model";

const suportaServiceWorker = "serviceWorker" in navigator;

const urlBase64ToUint8Array = base64String => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const getPublicApplicationServerKey = publicKey => {
    return urlBase64ToUint8Array(publicKey);
};

export const permitirNotificacoesNoBrowser = (permitirPush, bloquearPush, email, publicKey, dispatch) => event => {
    if (suportaServiceWorker) {
        if (event.target.checked) {
            Notification.requestPermission().then(permissao => {
                if (permissao === "granted") {
                    navigator.serviceWorker.ready
                        .then(registration =>
                            registration.pushManager.getSubscription().then(subs => {
                                if (subs) return subs;
                                return registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: getPublicApplicationServerKey(publicKey)
                                });
                            })
                        )
                        .then(subs => subs.toJSON())
                        .then(subs => {
                            if (subs) {
                                dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: true });
                                permitirPush({
                                    variables: {
                                        pushSubscribeTo: {
                                            auth: subs.keys?.auth,
                                            key: subs.keys?.p256dh,
                                            endpoint: subs.endpoint
                                        },
                                        email
                                    }
                                })
                                .then(({errors, data}) => {
                                    if (errors || !data || !data.permitirPushSubscription) {
                                        console.error("erro ao tentar permitir push");
                                        dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: false });
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                    dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: false });
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }
            });
        } else {
            navigator.serviceWorker.ready
                .then(registration =>
                    registration.pushManager.getSubscription().then(subs => {
                        if (subs) {
                            dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: false });
                            bloquearPush({
                                variables: {
                                    pushSubscribeTo: {
                                        endpoint: subs.endpoint
                                    },
                                    email
                                }
                            })
                            .then(({errors, data}) => {
                                if (errors || !data || !data.bloquearPushSubscription) {
                                    console.error("erro ao tentar bloquear push");
                                    dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: true });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: true });
                            });
                            return;
                        }
                        return Promise.resolve(true);
                    })
                )
                .catch(error => {
                    console.error(error);
                    dispatch({ type: TipoAcaoApp.SET_NOTIFICACOES_ATIVAS, data: true });
                });
        }
    }
};

export const ativaOuDesativaNotificacaoClipping = (
    tipoNotificacaoPush: TipoNotificacaoApp,
    dispatch,
    adicionarTipoNotificacao,
    removerTipoNotificacao,
    email
) => event => {
    if (event.target.checked) {
        adicionarTipoNotificacao({
            variables: {
                tipo: tipoNotificacaoPush,
                email
            }
        });
        dispatch({ type: TipoAcaoApp.SET_TIPO_NOTIFICAO_USUARIO, data: tipoNotificacaoPush });
        return;
    }

    removerTipoNotificacao({
        variables: {
            tipo: tipoNotificacaoPush,
            email
        }
    });
    dispatch({ type: TipoAcaoApp.REMOVE_TIPO_NOTIFICAO_USUARIO, data: tipoNotificacaoPush });
};
