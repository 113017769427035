import gql from "graphql-tag";

export const permitirPushSubscription = () => {
    return gql`
        mutation permitirPushSubscription($pushSubscribeTo: PushSubscribeTo!, $email: String!) {
            permitirPushSubscription(pushSubscribeTo: $pushSubscribeTo, email: $email)
        }
    `;
};

export const bloquearPushNotification = () => {
    return gql`
        mutation bloquearPushSubscription($pushSubscribeTo: PushSubscribeTo!, $email: String!) {
            bloquearPushSubscription(pushSubscribeTo: $pushSubscribeTo, email: $email)
        }
    `;
};

export const adicionarTipoNotificacaoPush = () => {
    return gql`
        mutation adicionarTipoNotificacaoPush($tipo: String!, $email: String!) {
            adicionarTipoNotificacaoPush(tipo: $tipo, email: $email)
        }
    `;
};

export const removerTipoNotificacaoPush = () => {
    return gql`
        mutation removerTipoNotificacaoPush($tipo: String!, $email: String!) {
            removerTipoNotificacaoPush(tipo: $tipo, email: $email)
        }
    `;
};
