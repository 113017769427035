import { Box, BoxProps } from "@material-ui/core";
import React from "react";

const BoxCard: React.FC<BoxProps> = ({ children, ...props }) => {
    return (
        <Box bgcolor="background.paper" border={1} borderRadius="borderRadius" borderColor="#e1e1e1" {...props}>
            {children}
        </Box>
    );
};

export default BoxCard;
