import { Box, Grid, makeStyles } from "@material-ui/core";
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import Icon from "@mdi/react";
import {
    AgrupamentoRenavamQuery,
    DadosMarketShare,
    FiltrosSelecionados,
    TipoAgrupamentoMarketShare,
    TipoDadoIncluirMarketShareImpl,
    useLazyMarketShare
} from "api";
import { State } from "api/redux/model";
import { formatNumber } from "api/util/objectFunctions";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Routes } from "routes/Routes";
import BoxCard from "ui/components/BoxCard/BoxCard";
import ViewLayout from "ui/components/ViewLayout/ViewLayout";
import { ItemMktShareHome } from "ui/views/Home/model/ItemMktShareHome";

const homeStyles = makeStyles(() => ({
    gridRow: {
        background: "#f5f5f5"
    },
    buttonText: {
        textAlign: "left",
        fontSize: 15,
        textTransform: "capitalize"
    },
    imageStyle: {
        width: 25,
        height: 25
    },
    boxMarketShare: {
        fontWeight: "bold",
        backgroundColor: "#616161",
        color: "white",
        padding: 8
    }
}));

const getTiposDadosIncluirMarketShare = () => {
    return [
        TipoDadoIncluirMarketShareImpl.MTD,
        TipoDadoIncluirMarketShareImpl.MTD_PY,
        TipoDadoIncluirMarketShareImpl.MTD_PM,
        TipoDadoIncluirMarketShareImpl.YTD,
        TipoDadoIncluirMarketShareImpl.YTD_PY
    ];
};

const agrupamentoRenavam = new AgrupamentoRenavamQuery();
agrupamentoRenavam.comMarcaNome();
agrupamentoRenavam.comQuantidade();

const getFiltroPadrao = (state: State) => {
    const filtroPadrao = new FiltrosSelecionados();
    filtroPadrao.selecionaFiltrosPadroes(state.filtro);
    filtroPadrao.montadora = state.app.marcaSelecionada;
    filtroPadrao.setDatas(
        DateTime.fromFormat(state.app.ultimaDataEmplacamento, "dd/MM/yyyy")
            .startOf("month")
            .toFormat("yyyy-MM-dd"),
        DateTime.fromFormat(state.app.ultimaDataEmplacamento, "dd/MM/yyyy").toFormat("yyyy-MM-dd")
    );
    return filtroPadrao;
};

const getDadosHomeMktShare = (
    filtro: FiltrosSelecionados | undefined,
    dadosMarketShare: DadosMarketShare | undefined,
    carregandoDadosMarketShare: boolean
) => {
    if (!filtro || !dadosMarketShare || carregandoDadosMarketShare) {
        const itemCarregando = { key: 0, descricao: "", valor: "", icon: "", cor: "", carregando: true };
        return [{ ...itemCarregando, key: 1 }, { ...itemCarregando, key: 2 }, { ...itemCarregando, key: 3 }];
    }
    let chave = filtro.getNomeAgrupamentoOuMarca(filtro.marcaDaMontadora ? filtro.marcaDaMontadora.nome : "");

    const mktShareMtdPy = dadosMarketShare.percentualMtdPy(chave);
    const mktShareMtdPm = dadosMarketShare.percentualMtdPm(chave);
    const mktShareMtd = dadosMarketShare.percentualMtd(chave);
    const mktShareYtd = dadosMarketShare.percentualYtd(chave);
    const mktShareYtdPy = dadosMarketShare.percentualYtdPy(chave);
    const diffPercMtdVsPy = dadosMarketShare.evolucaoMtdVsPy(chave);
    const diffPercMtdVsPm = dadosMarketShare.evolucaoMtd(chave);
    const diffPercYtdVsYtdPy = dadosMarketShare.evolucaoYtd(chave);

    const itemMktShareMtdPm: ItemMktShareHome = {
        key: 1,
        descricao: `MTD vs PM`,
        valor: `${formatNumber(diffPercMtdVsPm, 1)} %`,
        icon: getIcon(mktShareMtd, mktShareMtdPm),
        cor: getColor(mktShareMtd, mktShareMtdPm),
        carregando: false
    };
    const itemMktShareMtdPy: ItemMktShareHome = {
        key: 2,
        descricao: `MTD vs PY`,
        valor: `${formatNumber(diffPercMtdVsPy, 1)} %`,
        icon: getIcon(mktShareMtd, mktShareMtdPy),
        cor: getColor(mktShareMtd, mktShareMtdPy),
        carregando: false
    };
    const itemMktShareYtdPy: ItemMktShareHome = {
        key: 3,
        descricao: `YTD vs PY`,
        valor: `${formatNumber(diffPercYtdVsYtdPy, 1)} %`,
        icon: getIcon(mktShareYtd, mktShareYtdPy),
        cor: getColor(mktShareYtd, mktShareYtdPy),
        carregando: false
    };
    return [itemMktShareMtdPm, itemMktShareMtdPy, itemMktShareYtdPy];
};

const getIcon = (base, comparacao) => {
    return base < comparacao ? mdiArrowDown : mdiArrowUp;
};

const getColor = (base, comparacao) => {
    return base < comparacao ? "red" : "green";
};

const Home = () => {
    const history = useHistory();
    const classes = homeStyles();
    const state = useSelector((state: State) => state);
    const [filtro, setFiltro] = useState<FiltrosSelecionados>();
    const [dadosMarketShare, setDadosMarketShare] = useState<DadosMarketShare>();

    const [carregaDadosMarketShare, { carregandoDadosMarketShare }] = useLazyMarketShare({
        tipoAgrupamentoMarketShare: TipoAgrupamentoMarketShare.MARCA_NOME,
        dadosDisponiveisNoMarketShare: getTiposDadosIncluirMarketShare(),
        agrupamentoRenavam,
        aposCarregar: dadosMarketShare => setDadosMarketShare(dadosMarketShare)
    });

    useEffect(() => {
        let filtroPadrao = getFiltroPadrao(state);
        setFiltro(filtroPadrao);
        carregaDadosMarketShare(filtroPadrao);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.app.marcaSelecionada]);

    return (
        <ViewLayout titulo="Home">
            <Grid container spacing={1} className={classes.gridRow}>
                <Grid item xs={12}>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                        {getDadosHomeMktShare(filtro, dadosMarketShare, carregandoDadosMarketShare).map(item => {
                            const children = item.carregando ? (
                                <div>Carregando...</div>
                            ) : (
                                <div>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <Box marginRight={0.5}>{item.valor}</Box>
                                            <Icon
                                                path={item.icon}
                                                size={0.5}
                                                style={{ backgroundColor: item.cor, borderRadius: 50 }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>{item.descricao}</div>
                                    </Grid>
                                </div>
                            );
                            return (
                                <BoxCard
                                    key={item.key}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width={100}
                                    height={60}
                                    className={classes.boxMarketShare}>
                                    {children}
                                </BoxCard>
                            );
                        })}
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.MARKET_SHARE.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img
                                        alt="Market Share"
                                        src="/img/market-share.png"
                                        className={classes.imageStyle}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.MARKET_SHARE.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.REGIONAL_ANALYSIS.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img
                                        alt="Regional Analysis"
                                        src="/img/regional-analysis.png"
                                        className={classes.imageStyle}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.REGIONAL_ANALYSIS.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.UF_ANALYSIS.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img alt="UF Analysis" src="/img/uf-analysis.png" className={classes.imageStyle} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.UF_ANALYSIS.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.MONTHLY.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img alt="Monthly" src="/img/monthly.png" className={classes.imageStyle} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.MONTHLY.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.TOTAL_INDUSTRY.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img
                                        alt="Total Industry"
                                        src="/img/total-industry.png"
                                        className={classes.imageStyle}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.TOTAL_INDUSTRY.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.MONTADORA.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img alt="Montadora" src="/img/montadora.png" className={classes.imageStyle} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.MONTADORA.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.SUBSEGMENTO.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img
                                        alt="Sub Segmentos"
                                        src="/img/sub-segmentos.png"
                                        className={classes.imageStyle}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.SUBSEGMENTO.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
                <Grid item xs={6}>
                    <BoxCard padding={1} onClick={() => history.push(Routes.SEGMENTO.route)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="left" marginBottom={0.5}>
                                    <img alt="Segmentos" src="/img/segmentos.png" className={classes.imageStyle} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttonText}>{Routes.SEGMENTO.label}</div>
                            </Grid>
                        </Grid>
                    </BoxCard>
                </Grid>
            </Grid>
        </ViewLayout>
    );
};

export default Home;
