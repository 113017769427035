import gql from "graphql-tag";

export const getConsultaClippingsDoUsuario = () => {
    return gql`
        query clippingDoUsuario($email: String!) {
            clippingDoUsuario(email: $email) {
                id
                descricao
                ajusteData
                tipoRelatorio
                posicao
                filtro
            }
        }
    `;
};
