export interface TipoFiltroVendaDireta {
    label: string;
    value: string;
}

export class TiposFiltrosVendaDireta {
    static TODOS: TipoFiltroVendaDireta = {
        label: "Todos",
        value: "TODOS"
    };
    static VENDAS_DEALER: TipoFiltroVendaDireta = {
        label: "Vendas Dealer",
        value: "VENDAS_DEALER"
    };
    static VENDA_DIRETA: TipoFiltroVendaDireta = {
        label: "Vendas Direta",
        value: "VENDA_DIRETA"
    };

    static todos(): Array<TipoFiltroVendaDireta> {
        return [this.TODOS, this.VENDAS_DEALER, this.VENDA_DIRETA];
    }
}
