import { useMutation } from "@apollo/react-hooks";
import { Box, Dialog, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup } from "@material-ui/core";
import { mdiArrowLeft, mdiEyePlus, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { SnackbarProviderContext } from "@tecsinapse/react-boilerplate/build/Providers";
import { AppBar, Button, Input } from "@tecsinapse/ui-kit";
import { FiltrosSelecionados } from "api";
import { State } from "api/redux/model";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConfiguracaoClippingProps } from "ui/components/ConfiguracaoClipping/ConfiguracaoClippingProps";
import { getDescricaoTipoRelatorio, TipoDataClipping } from "ui/components/ConfiguracaoClipping/enums";
import { ConfiguracaoClippingType } from "ui/components/ConfiguracaoClipping/model";
import {
    editarConfiguracaoClippingMutation,
    salvarConfiguracaoClippingMutation
} from "ui/components/ConfiguracaoClipping/mutations";
import { useClippingStyles } from "ui/components/ConfiguracaoClipping/styles/ClippingStyles";
import { SlideTransition } from "ui/components/SlideTransition";

const ConfiguracaoClipping: React.FC<ConfiguracaoClippingProps> = ({
    filtrosSelecionados = new FiltrosSelecionados(),
    tipoRelatorio,
    descricaoClipping = "",
    aposEditar,
    edicao = false,
    idClipping
}) => {
    const classes = useClippingStyles();

    const email = useSelector((state: State) => state.app.usuarioLogado.email);
    const [aberto, setAberto] = useState(false);
    const [descricao, setDescricao] = useState<String>("");
    const [ajusteData, setAjusteData] = useState(TipoDataClipping.DIA);
    const [mensagemErro, setMensagemErro] = useState("");
    const [salvaClipping] = useMutation(salvarConfiguracaoClippingMutation());
    const [editarClipping] = useMutation(editarConfiguracaoClippingMutation());
    const showSnackbar: any = useContext(SnackbarProviderContext);

    useEffect(() => {
        setDescricao(descricaoClipping);
    }, [descricaoClipping]);

    return (
        <>
            <IconButton onClick={() => setAberto(true)} className={classes.botaoAbrir}>
                <Icon path={edicao ? mdiPencil : mdiEyePlus} size={1} />
            </IconButton>

            <Dialog fullScreen open={aberto} onClose={() => setAberto(false)} TransitionComponent={SlideTransition}>
                <AppBar
                    className={classes.appBar}
                    menuBar={false}
                    disableBreadcrumb
                    title="Configuração de clipping"
                    leftIcons={
                        <IconButton edge="start" color="inherit" onClick={() => setAberto(false)} aria-label="close">
                            <Icon path={mdiArrowLeft} size={1} />
                        </IconButton>
                    }></AppBar>
                <Box padding={1}>
                    <Grid container spacing={1}>
                        <Grid item style={{ width: "100%" }}>
                            Relatório:{" "}
                            <span style={{ fontWeight: "bold" }}>{getDescricaoTipoRelatorio(tipoRelatorio)}</span>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Input
                                fullWidth
                                name="descricao"
                                label="Descrição"
                                value={descricao}
                                error={mensagemErro}
                                onChange={event => setDescricao(event.target.value)}
                            />
                        </Grid>
                        {!edicao && (
                            <Grid item style={{ width: "100%" }}>
                                <FormLabel component="legend">Tipo de Data</FormLabel>
                                <RadioGroup
                                    aria-label="tipoDataClipping"
                                    value={ajusteData}
                                    onChange={event => setAjusteData(TipoDataClipping[event.target.value])}>
                                    <FormControlLabel value={TipoDataClipping.DIA} control={<Radio />} label="Dia" />
                                    <FormControlLabel value={TipoDataClipping.MES} control={<Radio />} label="Mês" />
                                    <FormControlLabel value={TipoDataClipping.ANO} control={<Radio />} label="Ano" />
                                    <FormControlLabel
                                        value={TipoDataClipping.SEIS_MESES}
                                        control={<Radio />}
                                        label="6 Meses"
                                    />
                                    <FormControlLabel
                                        value={TipoDataClipping.DOZE_MESES}
                                        control={<Radio />}
                                        label="12 Meses"
                                    />
                                </RadioGroup>
                            </Grid>
                        )}
                        <Grid item style={{ width: "100%" }}>
                            <Button
                                customVariant="success"
                                fullWidth
                                onClick={() => {
                                    const configuracaoClipping: ConfiguracaoClippingType = {
                                        descricao,
                                        ajusteData,
                                        tipoRelatorio,
                                        filtro: filtrosSelecionados,
                                        email
                                    };

                                    if (!configuracaoClipping.descricao) {
                                        setMensagemErro("Informe a descrição");
                                        return false;
                                    } else {
                                        if (!edicao) {
                                            salvaClipping({
                                                variables: {
                                                    configuracaoClipping: {
                                                        ...configuracaoClipping,
                                                        filtro: filtrosSelecionados.selecionadosAsString()
                                                    }
                                                }
                                            });
                                            showSnackbar({
                                                variant: "success",
                                                text: "Relatório adicionado ao clipping",
                                                autoHide: 5000
                                            });
                                        } else {
                                            editarClipping({
                                                variables: {
                                                    id: idClipping,
                                                    descricao: descricao
                                                }
                                            });
                                            if (aposEditar) {
                                                aposEditar(descricao, idClipping);
                                            }
                                        }
                                        setDescricao("");
                                        setMensagemErro("");
                                        setAjusteData(TipoDataClipping.DIA);
                                        setAberto(false);
                                    }
                                }}>
                                Salvar Clipping
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

export default ConfiguracaoClipping;
