import { Estado, Estados } from "api/enums";
import { AgrupamentoMarcas, Marca } from "api/model";
import { FiltroState } from "api/redux/model/FiltroState";
import { JsonMember, JsonObject, TypedJSON } from "typedjson-npm";
import { TipoFiltroVendaDireta, TiposFiltrosVendaDireta } from "../enum";
import { OpcaoFiltro } from "./OpcaoFiltro";

@JsonObject
export class FiltrosSelecionados {
    @JsonMember({ type: String })
    montadora?: String;

    @JsonMember({ type: String })
    dataInicio: string = "";

    @JsonMember({ type: String })
    dataFim: string = "";

    @JsonMember({ type: Array, elements: String })
    tiposModelosSelecionados: Array<String> = [];
    tiposModelosDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    conceitosSelecionados: Array<String> = [];
    conceitosDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    segmentosSelecionados: Array<String> = [];
    segmentosDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    segmentosMarketingSelecionados: Array<String> = [];
    segmentosMarketingDisponveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    agrupamentoMarcasSelecionados: Array<String> = [];
    agrupamentoMarcasDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    marcasSelecionadas: Array<String> = [];
    marcasDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    divisoesRegionaisSelecionadas: Array<String> = [];
    divisoesRegionaisDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    estadosSelecionados: Array<String> = [];
    estadosDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: Array, elements: String })
    areasAtuacoesSelecionadas: Array<String> = [];
    areasAtuacoesDisponiveis: Array<OpcaoFiltro> = [];

    @JsonMember({ type: String })
    tipoFiltroVendaDiretaSelecionado?: String;
    tipoFiltroVendaDiretaDisponveis?: Array<OpcaoFiltro> = [];

    marcaDaMontadora?: Marca;

    selecionaFiltrosPadroes(filtroState: FiltroState) {
        this.setTiposModelos(filtroState.tiposModelosDisponiveis);
        this.tiposModelosDisponiveis = filtroState.tiposModelosDisponiveis;

        this.setConceitos(filtroState.conceitosDisponiveis);
        this.conceitosDisponiveis = filtroState.conceitosDisponiveis;

        this.setSegmentos(filtroState.segmentosDisponiveis);
        this.segmentosDisponiveis = filtroState.segmentosDisponiveis;

        this.setSegmentosMarketing(filtroState.segmentosMarketingDisponiveis);
        this.segmentosMarketingDisponveis = filtroState.segmentosMarketingDisponiveis;

        this.setMarcas(filtroState.marcasDisponiveis);
        this.marcasDisponiveis = filtroState.marcasDisponiveis;

        this.setAgrupamentosMarcas(filtroState.agrupamentosMarcasDisponiveis);
        this.agrupamentoMarcasDisponiveis = filtroState.agrupamentosMarcasDisponiveis;

        this.divisoesRegionaisDisponiveis = filtroState.divisoesRegionaisDisponiveis;

        this.areasAtuacoesDisponiveis = filtroState.areasAtuacaoDisponiveis;

        this.estadosDisponiveis = Estados.getEstados().map(
            estado => new OpcaoFiltro(estado.descricao, estado.sigla, true)
        );

        this.setTipoFiltroVendaDireta(TiposFiltrosVendaDireta.TODOS);
        this.tipoFiltroVendaDiretaDisponveis = TiposFiltrosVendaDireta.todos().map(
            tipoFiltroVendaDireta => new OpcaoFiltro(tipoFiltroVendaDireta.label, tipoFiltroVendaDireta.value, true)
        );

        this.marcaDaMontadora = filtroState.marcaDaMontadora;
    }

    setDatas(dataInicio: string, dataFim: string) {
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
    }

    setTiposModelos(tiposModelos: OpcaoFiltro[]) {
        this.tiposModelosSelecionados = tiposModelos.filter(option => option.padrao).map(option => option.value);
    }

    setConceitos(conceitos: OpcaoFiltro[]) {
        this.conceitosSelecionados = conceitos.filter(option => option.padrao).map(option => option.value);
    }

    setSegmentos(segmentos: OpcaoFiltro[]) {
        this.segmentosSelecionados = segmentos.filter(option => option.padrao).map(option => option.value);
    }

    setSegmentosMarketing(segmentosMarketing: OpcaoFiltro[]) {
        this.segmentosMarketingSelecionados = segmentosMarketing
            .filter(option => option.padrao)
            .map(option => option.value);
    }

    setMarcas(marcas: OpcaoFiltro[]) {
        this.marcasSelecionadas = marcas.filter(option => option.padrao).map(option => option.value);
    }

    setAgrupamentosMarcas(agrupamentoMarcas: OpcaoFiltro[]) {
        this.agrupamentoMarcasSelecionados = agrupamentoMarcas
            .filter(option => option.padrao)
            .map(option => option.value);
    }

    setDivisoesRegionais(divisoesRegionais: OpcaoFiltro[]) {
        this.divisoesRegionaisSelecionadas = divisoesRegionais
            .filter(option => option.padrao)
            .map(option => option.value);
    }

    setAreasAtuacao(areasAtuacoes: OpcaoFiltro[]) {
        this.areasAtuacoesSelecionadas = areasAtuacoes.filter(option => option.padrao).map(option => option.value);
    }

    setEstados(estados: Estado[]) {
        this.estadosSelecionados = estados.map(option => option.sigla);
    }

    setTipoFiltroVendaDireta(tipoVendaDireta: TipoFiltroVendaDireta) {
        this.tipoFiltroVendaDiretaSelecionado = tipoVendaDireta.value;
    }

    selecionarApenasMarcaDaMontadora() {
        if (this.marcaDaMontadora) {
            this.marcasSelecionadas = [this.marcaDaMontadora["codigo"]];
        }
    }

    disponivelApenasAgrupamentoDaMontadora() {
        if (this.agrupamentoMarcasDisponiveis && this.agrupamentoMarcasDisponiveis.length > 0) {
            this.agrupamentoMarcasDisponiveis = this.agrupamentoMarcasDisponiveis.filter(option =>
                option.item.marcas?.some(marca => marca.codigo === this.marcaDaMontadora?.codigo)
            );
        }
    }

    public selecionadosAsString(): string {
        return TypedJSON.stringify(this);
    }

    public static fromSelecionados(stringObject: string): FiltrosSelecionados {
        if (stringObject === null || stringObject.length === 0) {
            return new FiltrosSelecionados();
        }
        return TypedJSON.parse(stringObject, FiltrosSelecionados);
    }

    public clone(): FiltrosSelecionados {
        let novoFiltro = new FiltrosSelecionados();

        novoFiltro.montadora = this.montadora

        novoFiltro.dataInicio = this.dataInicio;
        novoFiltro.dataFim = this.dataFim;

        novoFiltro.tiposModelosSelecionados = this.tiposModelosSelecionados;
        novoFiltro.tiposModelosDisponiveis = this.tiposModelosDisponiveis;

        novoFiltro.conceitosSelecionados = this.conceitosSelecionados;
        novoFiltro.conceitosDisponiveis = this.conceitosDisponiveis;

        novoFiltro.segmentosSelecionados = this.segmentosSelecionados;
        novoFiltro.segmentosDisponiveis = this.segmentosDisponiveis;

        novoFiltro.segmentosMarketingSelecionados = this.segmentosMarketingSelecionados;
        novoFiltro.segmentosMarketingDisponveis = this.segmentosMarketingDisponveis;

        novoFiltro.marcasSelecionadas = this.marcasSelecionadas;
        novoFiltro.marcasDisponiveis = this.marcasDisponiveis;

        novoFiltro.agrupamentoMarcasSelecionados = this.agrupamentoMarcasSelecionados;
        novoFiltro.agrupamentoMarcasDisponiveis = this.agrupamentoMarcasDisponiveis;

        novoFiltro.divisoesRegionaisSelecionadas = this.divisoesRegionaisSelecionadas;
        novoFiltro.divisoesRegionaisDisponiveis = this.divisoesRegionaisDisponiveis;

        novoFiltro.areasAtuacoesSelecionadas = this.areasAtuacoesSelecionadas;
        novoFiltro.areasAtuacoesDisponiveis = this.areasAtuacoesDisponiveis;

        novoFiltro.estadosSelecionados = this.estadosSelecionados;
        novoFiltro.estadosDisponiveis = this.estadosDisponiveis;

        novoFiltro.tipoFiltroVendaDiretaSelecionado = this.tipoFiltroVendaDiretaSelecionado;
        novoFiltro.tipoFiltroVendaDiretaDisponveis = this.tipoFiltroVendaDiretaDisponveis;

        novoFiltro.marcaDaMontadora = this.marcaDaMontadora;
        return novoFiltro;
    }

    public obtemSegmentosSelecionadosAsSelectOptions(): OpcaoFiltro[] {
        return this.segmentosDisponiveis.filter(
            option => this.segmentosSelecionados.indexOf(option.value) >= 0
        );
    }

    public getAgrupamentosMarcasDisponiveisDosAgrupamentosMarcasSelecionados(): AgrupamentoMarcas[] {
        let disponiveisDaMarcaSelecionada: OpcaoFiltro[] = this.agrupamentoMarcasDisponiveis.filter(option =>
            this.agrupamentoMarcasSelecionados.some(value => value === option.value)
        );
        return disponiveisDaMarcaSelecionada.flatMap(option => option.item || []);
    }

    public getMarcasDosAgrupamentosMarcasSelecionados(): Marca [] {
        let disponiveisDaMarcaSelecionada: OpcaoFiltro[] = this.agrupamentoMarcasDisponiveis.filter(option =>
            this.agrupamentoMarcasSelecionados.some(value => value === option.value)
        );
        return disponiveisDaMarcaSelecionada.flatMap(option => option.item.marcas || []);
    }

    public getAgrupamentoMarcasSelecionadosAsOptions() {
        return this.agrupamentoMarcasDisponiveis
            .filter(agrupamento => this.agrupamentoMarcasSelecionados && this.agrupamentoMarcasSelecionados
                .some(selecionado => selecionado === agrupamento.value))
    }

    public getNomeAgrupamentoOuMarca(nomeMarca: string): string {
        const agrupamentoMarca = this.getAgrupamentoMarcasSelecionadosAsOptions().filter(option => {
            if (option.item && option.item.marcas) {
                return option.item.marcas.some(marca => marca && marca.nome === nomeMarca)
            }
            return false;
        })[0];
        
        return agrupamentoMarca ? agrupamentoMarca.label : nomeMarca;
    }

    selecionarTodosTiposModelos() {
        this.tiposModelosSelecionados = this.tiposModelosDisponiveis.map(option => option.value);
    }

    selecionarTodosConceitos() {
        this.conceitosSelecionados = this.conceitosDisponiveis.map(option => option.value);
    }

    selecionarTodosSegmentos() {
        this.segmentosSelecionados = this.segmentosDisponiveis.map(option => option.value);
    }

    selecionarTodosSegmentosMarketing() {
        this.segmentosMarketingSelecionados = this.segmentosMarketingDisponveis.map(option => option.value);
    }

    selecionarTodasMarcas() {
        this.marcasSelecionadas = this.marcasDisponiveis.map(option => option.value);
    }

    selecionarTodosTiposModelosConceitosSegmentosEMarcas() {
        this.selecionarTodosTiposModelos();
        this.selecionarTodosConceitos();
        this.selecionarTodosSegmentos();
        this.selecionarTodasMarcas();
        this.selecionarTodosSegmentosMarketing();
    }
}
