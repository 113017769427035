import { Box, Dialog } from "@material-ui/core";
import { mdiClose, mdiFilterVariant, mdiSend } from "@mdi/js";
import Icon from "@mdi/react";
import { AppBar, IconButton } from "@tecsinapse/ui-kit";
import { State } from "api/redux/model";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFiltroStyles } from "ui/components/Filtro/css/FiltroStyle";
import { SlideTransition } from "ui/components/SlideTransition";
import FiltroPeriodo from "./FiltroPeriodo";
import FiltroRegiao from "./FiltroRegiao";
import FiltroSegmentacao from "./FiltroSegmentacao";
import FiltroVisao from "./FiltroVisao";
import { FiltroProps } from "./model/FiltroProps";
import { FiltrosSelecionados } from "./model/FiltrosSelecionados";

const atualizaEstadoComOpcaoSelecionada = setFiltroSelecionados => nomeCampo => opcaoSelecionada => {
    setFiltroSelecionados(estadoAnterior => {
        let novoEstado = estadoAnterior.clone();
        novoEstado[nomeCampo] = opcaoSelecionada;
        return novoEstado;
    });
};

const fechaFiltro = setAberto => () => setAberto(false);

const Filtro: React.FC<FiltroProps> = ({ aplicarFiltro, customizaFiltro, periodo = true }) => {
    const classes = useFiltroStyles();
    const { filtro, app } = useSelector((state: State) => state);
    const [aberto, setAberto] = useState(false);
    const [filtroSelecionados, setFiltroSelecionados] = useState(new FiltrosSelecionados());

    useEffect(() => {
        const filtroPadrao = new FiltrosSelecionados();
        filtroPadrao.selecionaFiltrosPadroes(filtro);
        filtroPadrao.montadora = app.marcaSelecionada;
        filtroPadrao.setDatas(
            DateTime.fromFormat(app.ultimaDataEmplacamento, "dd/MM/yyyy")
                .startOf("month")
                .toFormat("yyyy-MM-dd"),
            DateTime.fromFormat(app.ultimaDataEmplacamento, "dd/MM/yyyy").toFormat("yyyy-MM-dd")
        );

        if (customizaFiltro) {
            customizaFiltro(filtroPadrao);
        }

        setFiltroSelecionados(filtroPadrao);
        aplicarFiltro(filtroPadrao);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customizaFiltro, app.marcaSelecionada, app.ultimaDataEmplacamento, filtro]);

    return (
        <>
            <IconButton onClick={() => setAberto(true)} style={{ width: 28, height: 28, padding: 2 }}>
                <Icon path={mdiFilterVariant} size={1} />
            </IconButton>
            <Dialog fullScreen open={aberto} onClose={fechaFiltro(setAberto)} TransitionComponent={SlideTransition}>
                <AppBar
                    title="Filtros"
                    className={classes.appBar}
                    menuBar={false}
                    disableBreadcrumb
                    leftIcons={
                        <IconButton edge="start" color="inherit" onClick={fechaFiltro(setAberto)} aria-label="close">
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    }
                    rightIcons={
                        <IconButton
                            onClick={() => {
                                fechaFiltro(setAberto)();
                                aplicarFiltro(filtroSelecionados);
                            }}>
                            <Icon path={mdiSend} size={1} />
                        </IconButton>
                    }></AppBar>
                <Box marginTop="60px">
                    <FiltroPeriodo
                        periodo={periodo}
                        filtrosSelecionados={filtroSelecionados}
                        updateState={atualizaEstadoComOpcaoSelecionada(setFiltroSelecionados)}
                    />
                    <FiltroSegmentacao
                        filtrosSelecionados={filtroSelecionados}
                        updateState={atualizaEstadoComOpcaoSelecionada(setFiltroSelecionados)}
                    />
                    <FiltroRegiao
                        filtrosSelecionados={filtroSelecionados}
                        updateState={atualizaEstadoComOpcaoSelecionada(setFiltroSelecionados)}
                    />
                    <FiltroVisao
                        filtrosSelecionados={filtroSelecionados}
                        updateState={atualizaEstadoComOpcaoSelecionada(setFiltroSelecionados)}
                    />
                </Box>
            </Dialog>
        </>
    );
};

export default Filtro;
