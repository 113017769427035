import { FiltrosSelecionados } from "api/model";
import { DateTime } from "luxon";
import { TypedJSON } from "typedjson-npm";
import { DadosMarketShare } from "./DadosMarketShare";

export interface TipoDadoIncluirMarketShare {
    getPrefixoConsulta(): string;
    getFiltroCustomizado(filtro: FiltrosSelecionados): FiltrosSelecionados;
    adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any);
}

function clonaFiltro(filtro): FiltrosSelecionados {
    return TypedJSON.parse(TypedJSON.stringify(filtro), FiltrosSelecionados);
}

export class TipoDadoIncluirMarketShareImpl {
    static DAY: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "day";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = novoFiltro.dataFim;
            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeDia(data[this.getPrefixoConsulta()]);
        }
    };
    static DAY_PD: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "day_pd";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            let DAYS_TO_SUBTRACT = 1;
            const dataFim = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            );
            if (dataFim.weekday === 1) {
                DAYS_TO_SUBTRACT = 2;
            }
            novoFiltro.dataFim = dataFim
                .minus({ day: DAYS_TO_SUBTRACT })
                .toFormat("yyyy-MM-dd");
            novoFiltro.dataInicio = novoFiltro.dataFim;
            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeDiaPd(data[this.getPrefixoConsulta()]);
        }
    };
    static MTD: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "mtd";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .startOf("month")
                .toFormat("yyyy-MM-dd");
            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeMtd(data[this.getPrefixoConsulta()]);
        }
    };
    static MTD_PM: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "mtd_pm";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .startOf("month")
                .minus({ month: 1 })
                .toFormat("yyyy-MM-dd");
            novoFiltro.dataFim = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .minus({ month: 1 })
                .toFormat("yyyy-MM-dd");

            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeMtdPm(data[this.getPrefixoConsulta()]);
        }
    };
    static MTD_PY: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "mtd_py";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .startOf("month")
                .minus({ year: 1 })
                .toFormat("yyyy-MM-dd");
            novoFiltro.dataFim = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .minus({ year: 1 })
                .toFormat("yyyy-MM-dd");

            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeMtdPy(data[this.getPrefixoConsulta()]);
        }
    };
    static YTD: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "ytd";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .startOf("year")
                .toFormat("yyyy-MM-dd");

            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeYtd(data[this.getPrefixoConsulta()]);
        }
    };
    static YTD_PM: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "ytd_pm";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .startOf("year")
                .toFormat("yyyy-MM-dd");
            novoFiltro.dataFim = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .minus({ month: 1 })
                .endOf("month")
                .toFormat("yyyy-MM-dd");

            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeYtdPm(data[this.getPrefixoConsulta()]);
        }
    };
    static YTD_PY: TipoDadoIncluirMarketShare = {
        getPrefixoConsulta() {
            return "ytd_py";
        },
        getFiltroCustomizado(filtro: FiltrosSelecionados) {
            const novoFiltro = clonaFiltro(filtro);
            novoFiltro.dataInicio = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .startOf("year")
                .minus({ year: 1 })
                .toFormat("yyyy-MM-dd");
            novoFiltro.dataFim = DateTime.fromFormat(
                novoFiltro.dataFim,
                "yyyy-MM-dd"
            )
                .minus({ year: 1 })
                .toFormat("yyyy-MM-dd");

            return novoFiltro;
        },
        adicionaVolumeMarketShare(marketShare: DadosMarketShare, data: any) {
            marketShare.addVolumeYtdPy(data[this.getPrefixoConsulta()]);
        }
    };
}

export const TODOS_TIPOS_DADOS = [
    TipoDadoIncluirMarketShareImpl.DAY,
    TipoDadoIncluirMarketShareImpl.DAY_PD,
    TipoDadoIncluirMarketShareImpl.MTD,
    TipoDadoIncluirMarketShareImpl.MTD_PM,
    TipoDadoIncluirMarketShareImpl.MTD_PY,
    TipoDadoIncluirMarketShareImpl.YTD,
    TipoDadoIncluirMarketShareImpl.YTD_PM,
    TipoDadoIncluirMarketShareImpl.YTD_PY
];
